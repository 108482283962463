<template>
  <div class="container text-center">
    <gtm-page-view v-if="gtm_id" :gtm_id="gtm_id"></gtm-page-view>
    <gtm-purchase v-if="gtm_id" :gtm_id="gtm_id" :total_price="total_price" :items="items" :order_secret="order_secret"></gtm-purchase>

    <div class="pt-4 pb-4">
      <b-icon width="88px" height="88px" icon="check-circle-fill" font-scale="4" class="pos-accent-color check-icon bg-white rounded-circle"></b-icon>
    </div>
    <h1 class="title pos-secondary-color">{{ $t('pos_order_success_title', {order_number: order_number}) }}</h1>
    <p class="subtitle pos-secondary-color mt-2">{{ $t('order_thank_you_text') }}</p>
    <a :href="order_status_page_url" v-if="order_status_page_url">
      <b-button class="btn pos-btn-order mt-4 mb-5 btn-ticket-download">{{ $t('order_thank_you_btn_ticket_download') }}</b-button>
    </a>

    <div class="mt-4" v-if="upcoming_events && upcoming_events.length > 0">
        <p class="sub-title pos-secondary-color">{{ $t('order_thank_you_upcoming_events') }}</p>
        <b-row class="custom-gutter mt-2">
          <b-col v-for="event in upcoming_events" :key="event.id" sm="12" md="4" class="event-col">
            <div :style="event.image_url ? `background-image: url('${event.image_url}');` : ''" :class="{'has-image': event.image_url}" class="bg-image pos-bg-secondary-color border-radius-6 content p-3">
              <div v-if="event.image_url" :class="(event.image_url) ? 'overlay' : 'overlay dark'"></div>
              <div class="content">
                <div class="event-details">
                  <div class="event-name pos-primary-color">{{ event.name }}</div>
                  <div class="event-date pos-primary-color">{{ event.starts_at | date_written }}</div>
                </div>
                <a class="btn pos-btn-order btn-ticket-order-now" :href="getEventLink(event)">{{ $t('order_thank_you_btn_event_details') }}</a>
              </div>
            </div>
          </b-col>
        </b-row>
    </div>
  </div>
</template>

<script>
import api from '../api'
import GtmPageView from '@/components/GtmPageView'
import GtmPurchase from '@/components/GtmPurchase'
import {groupBy, getOrderStatusPageUrl} from "@/functions";

export default {
  components: {
    GtmPurchase,
    GtmPageView
  },
  data() {
    return {
      order_number: '',
      gtm_id: null,
      total_price: null,
      items: [],
      order_secret: null,
      order_status_page_url: '',
      upcoming_events: null,
      order: null
    }
  },
  created(){
    if(document.body.contains(document.getElementById('company-header'))){
      document.getElementById("company-header").scrollIntoView();
    }
    if(this.$route.query.order_number) {
      this.order_number = this.$route.query.order_number; 
    }
    if(this.$route.query.order_number && this.$route.query.order_secret){
      api.order().getByOrdernumberSecret(this.$route.query.order_number, this.$route.query.order_secret).then(order => {
        this.order = order;
        if(order && order.id && order.secret && order.company.domain){
          this.order_status_page_url = getOrderStatusPageUrl(order.id, order.secret)
        }
        if(order && order.event && order.event.gtm_id){

          // Create the GTM scripts
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer',order.event.gtm_id);

          let gtm_noscript = document.createElement('noscript');
          let gtm_iframe = document.createElement('iframe');
          gtm_iframe.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=' + order.event.gtm_id);
          gtm_iframe.setAttribute('height', '0');
          gtm_iframe.setAttribute('width', '0');
          gtm_iframe.setAttribute('style', 'display:none;visibility:hidden');
          gtm_noscript.appendChild(gtm_iframe);
          document.body.appendChild(gtm_noscript);

          const products = groupBy(order.order_products, (product) => {
            return product.product.name;
          });

          let items = [];

          for(const x in products) {
            const p = products[x][0];
            items.push({
              item_id: p.product.id,
              item_name: p.product.name,
              item_category: order.event.name,
              item_category2: 'Tickets',
              item_category3: p.product.product_group.name === 'event_default_group' ? '' : p.product.product_group.name,
              price: p.price,
              quantity: products[x].length
            });
          }

          this.order_secret = this.$route.query.order_secret
          this.total_price = order.total
          this.items = items;
          this.gtm_id = order.event.gtm_id
        }

        if(order && order.company && order.company.order_thank_you_show_events){
          api.event().getUpcomingEvents(order.company.id, order.event.id).then(events => {
            this.upcoming_events = events;
          })
        }

      })
    }
    document.title = this.$t('order_thank_you_title')
  },
  methods:{
    getEventLink(event){
      let posLink = ''
      event.points_of_sale.some(pos => {
        if(pos.status === 'live' && pos.show_in_overview){
          posLink = pos.url
          return true
        }
        return false
      })
      return 'https://' + this.order.company.domain + '/p/' + posLink + '?utm_source=fairtix&utm_medium=ticketshop&utm_campaign=order_thank_you_page_event_display'
    }
  
  }
}
</script>
<style scoped>
h1.title {
  font-size: 32px;
  font-weight:bold;
}

p.subtitle {
  font-size: 1.4em;
}

.btn-ticket-download {
  font-size: 1.4em !important;
  border-radius:6px !important;
  padding:14px 52px !important;
  font-weight:600 !important;
}

.btn-ticket-order-now {
  padding: 10px 15px !important;
}

.sub-title{
  margin-top:60px;
  font-size:2em
}

.custom-gutter {
  display: flex;
  flex-wrap: wrap;
  margin:0 -35px
}

.event-col{
  position: relative;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 10px;
  border-radius: 5px;
  height:230px;
  display:flex;
  flex-direction: column;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.event-details {
  flex: 0 1 auto;
}

.event-name{
  color:#FFF;
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
}

.event-date{
  color:#FFF;
  font-size: 1em;
  text-align: left;
}

.event-col a{
  padding:8px 40px;
  align-self: flex-start;
  display:inline-block;
}
.bg-image {
  position: relative;
  background-size: cover;
  background-position: center;
}

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
}
.overlay.dark{
  background-color: rgba(0, 0, 0, 0.75);
}

.has-image {
  .event-name, .event-date {
    color: #FFFFFF !important;
  }
}
@media (max-width:768px){
  .check-icon{
    width:60px!important;
    height:60px!important
  }
  h1.title ,.sub-title{
    font-size: 22px;
  }
  p.subtitle,.event-name{
    font-size: 16px;
  }
  .event-date{
     font-size: 14px;
  }
  .event-col{
    padding: 7px 0px;
    height:194px;
  }
  .event-col:last-of-type{
    padding-bottom:0
  }
  .event-col a{
    font-size: 12px;
  }
  .btn-ticket-download {
    font-size:18px!important;
    padding:10px 20px !important
  }
  .custom-gutter {
    margin:0
  }
}
</style>
