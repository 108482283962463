<template>
  <div id="company-page">
    <div v-if="company">
      <div id="company-wrapper">
        <div id="company-header">
          <b-container>
            <b-row>
              <b-col lg="3" sm="8" cols="5" id="logo-container">
                <a :href="company.layout.logo_url" v-if="company.layout.logo && company.layout.logo_url" target="_blank">
                  <b-img :src="company.layout.logo" :alt="company.name" fluid/>
                </a>
                <b-img v-else-if="company.layout.logo" :src="company.layout.logo" :alt="company.name" fluid/>
                <b-img v-else :src="require('./../assets/logo-light.png')" :alt="company.name" fluid/>
              </b-col>
              <b-col lg="9" sm="4" cols="7" class="d-flex justify-content-end align-items-center">
                <b-dropdown id="dropdown-right " :text="languageLabel"  class="languages">
                  <template v-slot:button-content>
                    <div>
                      <b-img class="selected-lang" :src="require('./../assets/icons/'+language+'.png')"/>
                      <p class="mb-0">{{language.toUpperCase()}}</p>
                      <b-icon icon="chevron-down" width="14" class="ml-3"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item @click="changeLanguage('nl')"><b-img class="lang-flag" :src="require('./../assets/icons/nl.png')"/><p class="mb-0 ml-2">{{ $t('lang_nl') }}</p></b-dropdown-item>
                  <b-dropdown-item @click="changeLanguage('de')"><b-img class="lang-flag" :src="require('./../assets/icons/de.png')"/><p class="mb-0 ml-2">{{ $t('lang_de') }}</p></b-dropdown-item>
                  <b-dropdown-item @click="changeLanguage('en')"><b-img class="lang-flag" :src="require('./../assets/icons/en.png')"/><p class="mb-0 ml-2">{{ $t('lang_en') }}</p></b-dropdown-item>
                  <b-dropdown-item @click="changeLanguage('es')"><b-img class="lang-flag" :src="require('./../assets/icons/es.png')"/><p class="mb-0 ml-2">{{ $t('lang_es') }}</p></b-dropdown-item>
                  <b-dropdown-item @click="changeLanguage('fr')"><b-img class="lang-flag" :src="require('./../assets/icons/fr.png')"/><p class="mb-0 ml-2">{{ $t('lang_fr') }}</p></b-dropdown-item>
                  <b-dropdown-item @click="changeLanguage('pt')"><b-img class="lang-flag" :src="require('./../assets/icons/pt.png')"/><p class="mb-0 ml-2">{{ $t('lang_pt') }}</p></b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div id="company-nav" v-if="event" :class="{'company-nav-pages': $route.name !== 'ViewPointOfSale'}">
          <b-container class="px-0">
            <b-alert v-if="this.pos && this.pos.status === 'test'" variant="warning" show class="text-center">
              {{ $t('pos_in_test_mode') }}
            </b-alert>

            <div v-if="event.banner_url" class="text-center position-relative banner-container">
              <div class="banner-background" :style='`background-image: url("${event.banner_url}");`'></div>
              <img :src="event.banner_url" alt="" class="pos-banner">
            </div>

            <b-row class="event-top-header mx-0" :class="{'with-banner': event.banner_url}">
              <b-col sm="12" lg="7" class="pl-0 d-flex align-items-center mb-4 mb-lg-0">
                <div v-if="event && event.starts_at && event.ends_at" class="mr-3 mr-md-4">
                  <CalenderDay :starts-at="event.starts_at" :ends-at="event.ends_at"/>
                </div>
                <div>
                  <h1 class="event-title">{{ event.name }}</h1>
                  <p class="event-date event-date-web mb-0" v-if="event.starts_at">{{ getDateFormat(event.starts_at) }} - {{ getDateFormat(event.ends_at) }}</p>
                  <p class="event-date event-date-mobile mb-0" v-if="event.starts_at">{{ getDateFormatMobile(event.starts_at) }} - {{ getDateFormatMobile(event.ends_at) }}</p>
                  <p class="event-venue mb-0" v-if="event.venue_id && event.venue">{{ event.venue.name }}, {{ event.venue.address }}, {{ event.venue.city }}, {{ event.venue.zip }}, {{ event.venue.country_code }}</p>
                </div>
              </b-col>
              <b-col v-if="!countdown" sm="12" lg="5" class="stepper-wrapper pr-lg-0">
                <b-row class="stepper" align-h="end">
                  <b-col :cols="stepperCols" lg="auto" class="step" :class="step.done && $route.name !== step.route ? 'step-done' : ($route.name === step.route ? 'step-current' :'step-todo')"
                         v-for="(step, i) in steps"
                         :key="step.route+i">
                    <div class="w-100">
                      <div class="ball-wrapper">
                        <div class="ball pos-bg-secondary-color">
                          <b-icon v-if="step.done && $route.name !== step.route" icon="check-lg" width="16" height="14"></b-icon>
                        </div>
                      </div>
                      <div class="label pos-accent-color">{{ step.label }}</div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div id="company-content">
          <b-row>
            <b-col class="mt-5" v-if="countdown">
              <FlipCountdown :deadline="countdown"></FlipCountdown>
            </b-col>
            <b-col v-else>
              <router-view></router-view>
            </b-col>
          </b-row>
        </div>
      </div>
      <div id="company-wrapper-2">
        <div class="company-content">
          <b-container v-if="!countdown" class="footer-info-blocks">
            <b-row lg="6">
              <b-col class="info-card">
                <h3>
                  <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" height="19" width="19" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
                  {{ $t('shop_questions_event') }}
                </h3>
                <p class="footer-description">
                  {{ $t('shop_questions_event_hint') }}
                </p>
                <div class="mb-0">
                  <tr>
                    <td class="text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10.792" height="16.958" viewBox="0 0 10.792 16.958">
                        <path id="Path_143" data-name="Path 143" d="M1.542,16.958a1.485,1.485,0,0,1-1.089-.453A1.485,1.485,0,0,1,0,15.417V1.542A1.485,1.485,0,0,1,.453.453,1.485,1.485,0,0,1,1.542,0H9.25a1.485,1.485,0,0,1,1.089.453,1.485,1.485,0,0,1,.453,1.089V15.417A1.546,1.546,0,0,1,9.25,16.958Zm0-3.854v2.313H9.25V13.1ZM5.4,15.031a.757.757,0,1,0-.549-.222A.746.746,0,0,0,5.4,15.031ZM1.542,11.563H9.25V3.854H1.542Zm0-9.25H9.25V1.542H1.542Z" fill="#0a2337"/>
                      </svg>
                    </td>
                    <td><p class="m-0">{{ company.phone }}</p></td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.417" height="12.333" viewBox="0 0 15.417 12.333">
                        <path id="Path_144" data-name="Path 144" d="M1.542,12.333A1.485,1.485,0,0,1,.453,11.88,1.485,1.485,0,0,1,0,10.792V1.542A1.485,1.485,0,0,1,.453.453,1.485,1.485,0,0,1,1.542,0H13.875a1.484,1.484,0,0,1,1.089.453,1.485,1.485,0,0,1,.453,1.089v9.25a1.546,1.546,0,0,1-1.542,1.542Zm6.167-5.4L1.542,3.083v7.708H13.875V3.083Zm0-1.542,6.167-3.854H1.542ZM1.542,3.083v0Z" fill="#0a2337"/>
                      </svg>
                    </td>
                    <td>
                      <p class="m-0">{{ company.email_customer_questions }}</p>
                    </td>
                  </tr>
                  <tr v-if="company.show_kvk_on_pos">
                    <td class="text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" height="14" width="10.5" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 464c-8.8 0-16-7.2-16-16L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zm56 256c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0z"/></svg>
                    </td>
                    <td>
                      <p class="m-0">{{ $t('shop_chamber_of_commerce_number') }}: {{ company.kvk }}</p>
                    </td>
                  </tr>
                </div>
              </b-col>
              <b-col class="footer-info-blocks-border" cols="12">
                <div></div>
              </b-col>
              <b-col lg="6" class="info-card info-block">
                <h3>
                  <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" height="19" width="19" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
                  {{ $t('shop_questions_tickets') }}
                </h3>
                <p class="footer-description">
                  {{ $t('shop_questions_tickets_hint') }}
                </p>
                <div>
                  <tr>
                    <td class="text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10.792" height="16.958" viewBox="0 0 10.792 16.958">
                        <path id="Path_143" data-name="Path 143" d="M1.542,16.958a1.485,1.485,0,0,1-1.089-.453A1.485,1.485,0,0,1,0,15.417V1.542A1.485,1.485,0,0,1,.453.453,1.485,1.485,0,0,1,1.542,0H9.25a1.485,1.485,0,0,1,1.089.453,1.485,1.485,0,0,1,.453,1.089V15.417A1.546,1.546,0,0,1,9.25,16.958Zm0-3.854v2.313H9.25V13.1ZM5.4,15.031a.757.757,0,1,0-.549-.222A.746.746,0,0,0,5.4,15.031ZM1.542,11.563H9.25V3.854H1.542Zm0-9.25H9.25V1.542H1.542Z" fill="#0a2337"/>
                      </svg>
                    </td>
                    <td>
                      <p class="m-0">+31 (0) 85 0603 589</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.417" height="12.333" viewBox="0 0 15.417 12.333">
                        <path id="Path_144" data-name="Path 144" d="M1.542,12.333A1.485,1.485,0,0,1,.453,11.88,1.485,1.485,0,0,1,0,10.792V1.542A1.485,1.485,0,0,1,.453.453,1.485,1.485,0,0,1,1.542,0H13.875a1.484,1.484,0,0,1,1.089.453,1.485,1.485,0,0,1,.453,1.089v9.25a1.546,1.546,0,0,1-1.542,1.542Zm6.167-5.4L1.542,3.083v7.708H13.875V3.083Zm0-1.542,6.167-3.854H1.542ZM1.542,3.083v0Z" fill="#0a2337"/>
                      </svg>
                    </td>
                    <td>
                      <p class="m-0">support@fairtix.io</p>
                    </td>
                  </tr>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
      <div id="company-footer">
        <b-container class="company-footer px-0">
          <b-row>
            <b-col class="first">
              <img src="https://prod-ft-frontend.s3.eu-west-1.amazonaws.com/img/logo-fairtix.png" alt="Fairtix" style="max-width: 174px;"/>
            </b-col>
            <b-col class="footer-fairtix-border" cols="12">
              <div></div>
            </b-col>
            <b-col class="footer-fairtix">
              <p class="footer-strong">Fairtix</p>
              <p>{{ $t('shop_chamber_of_commerce_number') }}: {{ $t('fairtix_location_coc') }}</p>
              <p>{{ $t('shop_vat') }}: {{ $t('fairtix_location_vat') }}</p>
            </b-col>
            <b-col class="footer-fairtix-border" cols="12">
              <div></div>
            </b-col>
            <b-col class="footer-address">
              <p class="footer-strong">{{ $t('address') }}</p>
              <p>{{ $t('fairtix_location_street') }}</p>
              <p>{{ $t('fairtix_location_zip') }} {{ $t('fairtix_location_city') }}</p>
              <p>{{ $t('country_nl') }}</p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <b-row v-else>
      <b-col class="text-center">
        <b-spinner style="width: 10em; height: 10em;"></b-spinner>
      </b-col>
    </b-row>
    <CookieBanner></CookieBanner>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import api from "@/api";
import FlipCountdown from 'vue2-flip-countdown';
import CookieBanner from "@/components/CookieBanner";
import moment from "moment/moment";
import {getCookie} from "@/functions";
import CalenderDay from "@/components/CalenderDay"

export default {
  name: 'App',
  data() {
    return {
      show: false,
      countdown: null,
      countdownInterval: null,
      selectedCategories: {
        analytics: false,
        marketing: false,
        local_information: false
      }
    }
  },
  methods: {
    gtag(){
      window.dataLayer.push(arguments);
    },
    changeLanguage(lang) {
      this.$store.commit('setLanguage', lang)
    },
    loadCampaign() {
      if(this.$route.query.ft_campaign_name) {
        api.campaign().findByDomain(this.$route.query.ft_campaign_name).then(response => {
          if(response.campaign) {
            this.$store.commit('setCampaign', response.campaign);
          }
        })
      }
    },
    getDateFormat(date) {
      return moment(date).format('D MMM YYYY HH:mm');
    },
    getDateFormatMobile(date) {
      return moment(date).format('DD-MM-YYYY HH:mm');
    },
    ...mapActions(['loadCompany','loadProductTypeDonation'])
  },
  computed: {
    languageLabel(){
      return this.$t('language_' + this.language)
    },
    hasTimeslots() {
      if (!this.event) {
        return false
      }
      return this.event.timeslots.length > 0 || (this.event.opening_hours && this.event.opening_hours.length > 0)
    },
    stepperCols() {
      if (!this.steps) {
        return 12
      }
      return Math.floor(12 / this.steps.length)
    },
    steps() {
      let steps = [
        {label: this.$t('shop_tickets'), route: 'ViewPointOfSale', done: true},
      ]

      if (this.hasTimeslots) {
        steps.push({label: this.$t('shop_timeslot'), route: 'OrderSelectTimeslot', done: false})
      }

      steps.push({label: this.$t('shop_personal_details'), route: 'OrderEnterPersonalDetails', done: false})

      if (this.posHasCrossSell) {
        steps.push({label: this.$t('shop_additional_products'), route: 'OrderAdditionalProducts', done: false})
      }

      steps.push({label: this.$t('shop_pay'), route: 'OrderSelectPaymentMethod', done: false})

      let done = true
      steps.forEach((step, index) => {
        steps[index].done = done
        if (this.$route.name === step.route) {
          done = false
        }
      })
      return steps
    },
    ...mapState(['company', 'event', 'pos', 'language','donation_product_id']),
    ...mapGetters(['posHasCrossSell'])
  },
  watch: {
    company() {

      if (!this.company.layout) {
        this.company.layout = {
          header: {
            backgroundColor: '#efefef'
          },
          secondary: {
            backgroundColor: '#000',
            color: '#fff'
          },
          primary: {
            color: '#E6A510'
          },
          footer: {
            backgroundColor: '#efefef',
            links: [
              {text: 'Fairtix.io', url: 'https://fairtix.io'}
            ]
          }
        }
      }

      // apply custom styling
      let customCss = document.getElementById('company-css')
      if (customCss) {
        customCss.remove()
      }
      let layout = this.company.layout
      let css = ``

      if(layout.primary_color && layout.primary_color !== 'undefined' ){
        css += `div.flip-clock b.flip-card__bottom { background:${layout.primary_color}; }`;
        css += `div.flip-clock b.flip-card__top, div.flip-clock b.flip-card__back-bottom { background:${layout.primary_color}; }`;
        css += `#dropdown-right, #dropdown-right .dropdown-toggle { background-color:${layout.primary_color}; } `;
        css += `div.flip-clock b.flip-card__top, div.flip-clock b.flip-card__bottom, div.flip-clock b.flip-card__back-bottom, div.flip-clock b.flip-card__back::before, div.flip-clock b.flip-card__back::after { color:${layout.primary_color}; }`
        css += `#company-header { background:${layout.primary_color}; }`
        css += `#company-wrapper {background:${layout.primary_color} !important; }`

        css += `h1, h2, h3 { color:${layout.primary_color}; }`
        css += `.pos-primary-color { color:${layout.primary_color} !important; }`;
        css += `.pos-primary-color-border { border-color:${layout.primary_color} !important; }`;
        css += `.pos-bg-primary-color { background-color:${layout.primary_color} !important; }`;
        css += `.input-wrapper .form-control {border: 1px solid ${layout.primary_color} !important; color: ${layout.primary_color} !important;}`
        css += `.input-wrapper .form-control::placeholder {color: ${layout.primary_color} !important; opacity: 60%;}`
        css += `.input-wrapper .mt-icon svg {fill: ${layout.primary_color} !important; }`
        css += `.payment-method-row {border-top: 1px solid ${layout.primary_color};}`
        css += `.payment-method-row:nth-of-type(2n+1) {border-right: 1px solid ${layout.primary_color};}`
        css += `.pos-cart-place-order .discount-input { border-top: 1px solid ${layout.primary_color}; border-bottom: 1px solid ${layout.primary_color};}`;
        css += `.pos-cart-place-order .input-button-percentage { border-top: 1px solid ${layout.primary_color};border-left: 1px solid ${layout.primary_color};border-bottom: 1px solid ${layout.primary_color};}`
        css += `.pos-cart-place-order .input-button { background-color: ${layout.primary_color}; border-top: 1px solid ${layout.primary_color};border-bottom: 1px solid ${layout.primary_color};}`
        css += `.pos-cart-place-order .cart-discountcode {border: 1px solid ${layout.primary_color};}`;
        css += `.pos-cart-place-order .discount-input input {border-color: ${layout.primary_color}; color: ${layout.primary_color}}`;
        css += `.custom-control-input:checked ~ .custom-control-label::before { border-color: ${layout.primary_color} !important; background-color: ${layout.primary_color} !important;}`;
        css += `.custom-control-input:focus:not(:checked) ~ .custom-control-label::before { border-color: ${layout.primary_color} !important; }`;
        css += `.footer-info-blocks .info-card p { color: ${layout.primary_color} !important}`;
        css += `h2.group-title { color: ${layout.primary_color} !important }`;
        css += `.row.active-option .payment-method-row label.custom-control-label{border:1px solid ${layout.primary_color} !important;background-color:${layout.primary_color}10}`;
        css += `h1.event-title { color:${layout.primary_color}; }`;
        css += `.product-details h4, .content-block.cart-container h2, .content-block.cart-container h3 { color: ${layout.primary_color} }`;
        css += `svg:not(.bi-check-lg):not(.download-ticket-icon) {fill: ${layout.primary_color};}`
        css += `svg.cart-shop-icon path, svg.cart-shop-icon polygon {fill: ${layout.primary_color};}`;
        css += `svg path#Path_143, svg path#Path_144 {fill: ${layout.primary_color};}`;
        css += `svg path#Path_152, svg path#Path_153 {stroke: ${layout.primary_color};}`;
        css += `#company-footer p {color: ${layout.primary_color} !important}; }`;
        css += `.payment-method-row {border-top:1px solid ${layout.primary_color} !important; border-right:1px solid ${layout.primary_color}} !important; }`;
      }

      if(layout.secondary_color && layout.secondary_color !== 'undefined' ){
        css += `body { background-color: ${layout.secondary_color} !important;}`
        css += `#company-page { background-color: ${layout.secondary_color} !important; };`
        css += `.content-block.cart-container { background: ${layout.secondary_color} } `
        css += `.pos-secondary-color { color: ${layout.secondary_color} !important; } `
        css += `.pos-bg-secondary-color { background-color: ${layout.secondary_color}!important; } `
        css += `.pos-secondary-color-border { border-color: ${layout.secondary_color}!important; } `;
        css += `.stepper .step-todo .ball { color: ${layout.secondary_color};  }`;
        css += `.stepper .step-done .ball { color: ${layout.secondary_color}; }`;
        css += `svg.bi-download {fill: ${layout.secondary_color} !important;}`;
        css += `svg.bi-arrow-left {fill: ${layout.secondary_color} !important;}`;
        css += `.event-top-header {background: ${layout.secondary_color} !important;}`;
        css += `.content-block {background: ${layout.secondary_color} !important;}`;
        css += `.event-card {background: ${layout.secondary_color} !important;}`;
        css += `#company-footer {background: ${layout.secondary_color} !important;}`;
        css += `.footer-info-blocks {background: ${layout.secondary_color} !important; border: 1px solid #EAEAEA !important;}`;
        css += `.payment-method-row {background: ${layout.secondary_color} !important;}`
        css += `.order-status-page .content-header {background-color: ${layout.secondary_color} !important;}`
        css += `.order-status-page .ticket-row-wrapper {background-color: ${layout.secondary_color} !important;}`
      }

      if(layout.accent_color && layout.accent_color !== 'undefined' ){
        css += `.stepper .step-done .ball { background: ${layout.accent_color};line-height:3.2em !important }`;
        css += `.stepper .step-todo .ball { border: 3px solid #EAEAEA;line-height:2.9em !important }`;
        css += `p.event-date{ color:${layout.accent_color} !important; }`
        css += `.pos-accent-color{ color:${layout.accent_color} !important; }`
        css += `.pos-bg-accent-color{ background-color:${layout.accent_color} !important; }`
        css += `.pos-bg-accent-color-border{ border-color:${layout.accent_color} !important; }`
        css += `.stepper .step.step-current .ball, .stepper .step.step-done .ball {background-color: ${layout.accent_color} !important;border: 2px solid ${layout.accent_color} !important;}`;
        css += `.active-option {background-color: ${layout.accent_color} !important;}`
        css += `.pos-terms a {color: ${layout.accent_color} !important; }`;

        css += `.stepper-line { background: ${layout.accent_color};  }`;
        css += `.stepper .step-todo .ball { background: ${layout.accent_color}; }`;
        css += `.stepper .step-done div.label, .stepper .step-todo div.label{ color:${layout.accent_color}; }`;
        css += `svg.bi-check-circle-fill {fill: ${layout.accent_color};}`;
        css += `.btn-download-all-tickets {background-color: ${layout.accent_color}!important; color: ${layout.secondary_color} !important;}`
      }

      if(layout.description_color && layout.description_color !== 'undefined') {
        css += `p.event-venue{ color:${layout.description_color} !important; }`;
        css += `.product-details p, .content-block.cart-container .product-details p { color: ${layout.description_color} !important }`;
        css += `.pos-description-color { color: ${layout.description_color} !important }`
      }

      if(layout.button_color && layout.button_text_color) {
        css += `.content-block.cart-container .btn.btn-success{ background: ${layout.button_color}; color: ${layout.button_text_color} }`
        css += `.btn.pos-btn-order{ background: ${layout.button_color} !important; color: ${layout.button_text_color} !important }`
      }

      // Needed but not generated CSS
      css += `.content-block{ background: #fff; padding:25px 47px; }`
      css += `.row:first-child .content-block {}`
      css += `h1 { font-size: 1.8em }`
      css += `h2 { font-size: 1.6em }`
      css += `h3 { font-size: 1.4em }`

      document.querySelector('head').innerHTML += '<style id="company-css">' + css + '</style>'

    },
    pos(){
      // Check if we have to add GTM code
      if(this.pos && this.pos.event && this.pos.event.gtm_id){
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer',this.pos.event.gtm_id);

        let gtm_noscript = document.createElement('noscript');
        let gtm_iframe = document.createElement('iframe');
        gtm_iframe.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=' + this.pos.event.gtm_id);
        gtm_iframe.setAttribute('height', '0');
        gtm_iframe.setAttribute('width', '0');
        gtm_iframe.setAttribute('style', 'display:none;visibility:hidden');
        gtm_noscript.appendChild(gtm_iframe);
        document.body.appendChild(gtm_noscript);

        // Initialize Google Tag Manager
        this.gtag('js', new Date());
        this.gtag('config', this.pos.event.gtm_id);
      }
    }
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    const storedCategories = getCookie("ft_cookieCategories");
    if (storedCategories) {
      this.selectedCategories = JSON.parse(storedCategories);
      this.gtag('consent', 'default', {
        'ad_storage': (this.selectedCategories.marketing) ? 'granted' : 'denied',
        'ad_personalization': (this.selectedCategories.marketing) ? 'granted' : 'denied',
        'ad_user_data': (this.selectedCategories.marketing) ? 'granted' : 'denied',
        'analytics_storage': (this.selectedCategories.analytics) ? 'granted' : 'denied'
      })
    } else {
      this.gtag('consent', 'default', {
        'ad_storage': 'denied',
        'ad_personalization': 'denied',
        'ad_user_data': 'denied',
        'analytics_storage': 'denied',
      })
    }
  },
  created() {
    this.loadCompany()
    this.loadProductTypeDonation()
    this.loadCampaign();
    this.countdownInterval = setInterval(() => {
      if(this.pos && !this.pos.countdown) {
        this.countdown = null;
        clearInterval(this.countdownInterval)
      }
      if( this.pos && this.pos.countdown && moment(this.pos.countdown).isAfter(moment())) {
        this.countdown = moment(this.pos.countdown).format('YYYY-MM-DD HH:mm:ss');
      }
      else {
        clearInterval(this.countdownInterval)
        this.countdown = null;
      }
    }, 1300)
  },
  components: {
    FlipCountdown,
    CookieBanner,
    CalenderDay
  },
  destroyed() {
    clearInterval(this.countdownInterval)
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
</style>
<style>

.selected-lang{
  border:0px
}
.dropdown-toggle::after{
  margin-bottom:-4px
}
.btn{
  border:0px
}
#company-page {
  min-height: 100%;
}
#company-wrapper {
  overflow-y: hidden;
  background: #e5e5e5;
  overflow-x: hidden;
}
#company-wrapper-2 {
  overflow-y: auto;
  overflow-x: hidden;
  transform: translateY(-100px);
}
#company-header {
  padding: 35px 0;
}
#logo-container{
  display:flex;
  align-items: center;
}
#logo-container img{
  max-width: 171px;
  max-height: 57px;
}
#company-nav{
  margin-bottom:35px
}
.company-nav-pages {
  margin-bottom: 15px !important;
}
#company-content {
  margin: 0px 0 135px 0;
}
h1.event-title{
  font-weight:bold;
  font-size:32px;
  margin-bottom:0
}
p.event-date{
  font-size: 18px;
  font-weight: 600;
}
p.event-venue{
  font-size: 16px;
}
#company-footer {
  background-color:#FFF !important;
  margin-bottom: 7rem;
}
#company-footer div.col{
  padding:10px 0 10px 70px;
  border-left:1px solid #e0e0e0
}
#company-footer div.col.first{
  border-left:0px;
}
.info-card {
  padding-top: 0;
  padding-bottom: 0;
}
.info-card h3{
  font-size:16px;
  font-weight:600;
}
.info-card p{
  font-size:12px;
}
.info-card p svg{
  font-size:1rem
}
.footer-info-blocks {
  padding: 35px 47px;
  border-radius: 6px;
}

.footer-info-blocks .info-card{
  border:0px
}
.info-card:nth-child(1) {
  border-right: 1px solid #EAEAEA;
  padding-right:1rem!important;
}
.info-card:last-of-type {
  padding-left: 3rem;
}
.stepper-wrapper {
  position:relative;
  overflow:hidden
}

/* after element is the line between the steps */
.stepper-wrapper::after{
  content:'';
  position:absolute;
  top:19px;
  left:0;
  width:100%;
  height:2px;
  background-color:#EAEAEA
}

.stepper{
  gap:1rem;
  flex-wrap:nowrap
}

.stepper .step {
  text-align: center;
  display: flex;
  position:relative;
  flex-shrink: 1;
}

/* to hide the line will appear after last step ball */
.stepper .step:last-of-type::after{
  content: '';
  position: absolute;
  right: 0;
  top: 19px;
  z-index: 10;
  width: calc(50% - 19px);
  height: 2px;
  background-color: #fff;
}

/* to hide the line will appear before first step ball */
.stepper .step:first-of-type::before{
  content: '';
  position: absolute;
  right: 50%;
  top: 19px;
  z-index: 10;
  width: 100rem;
  height: 2px;
  background-color: #fff;
}

.stepper .step .label {
  font-size: 12px;
  text-align: center;
  margin-top: 0.5em;
  display: inline-block;
  width: 100%;
  font-weight: bold;
}

/* to hide the line will appear before and after any step ball "some white space around the ball" */
.stepper .step .ball-wrapper{
  width:45px;
  margin:0 auto;
  z-index: 20;
  position: relative;
}

.stepper .step .ball {
  z-index: 1;
  border-radius: 100%;
  width: 39px;
  height: 39px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin:0 auto;
  box-shadow: 0 0 0 2px #FFFFFF inset;
  box-sizing: border-box;
}
.stepper .step.step-current .ball {
  z-index: 1;
  border-radius: 100%;
  width: 39px;
  height: 39px;
  text-align: center;
  position: relative;
  display: inline-block;
  box-shadow: 0 0 0 2px #FFFFFF inset;
  box-sizing: border-box;
}
.stepper .step.step-done .ball {
  z-index: 1;
  border-radius: 100%;
  width: 39px;
  height: 39px;
  text-align: center;
  position: relative;
  display: flex;
  align-items:center;
  justify-content:center;
  box-sizing: border-box;
  box-shadow: none;
}
.stepper .step.step-done .label {
 margin-top:.8rem
}
.stepper-line {
  height: 3px;
  width: 90px;
  margin-top: 14px;
  background-color: #EAEAEA !important;
}

.event-date-mobile {
  display: none !important;
}
.footer-info-blocks-border {
  display: none;
}
.footer-fairtix-border {
  display: none;
}
  #company-footer .footer-strong {
  font-weight: bold !important;
}
#company-footer p {
  font-size: 12px !important;
  margin-bottom: 0;
}

#company-footer div.col.first img{
  width: 174px !important;
}

#company-page * {
  font-family: "Montserrat", sans-serif !important;
}

.event-top-header {
  background-color: #FFFFFF;
  padding: 47px;
  border-radius: 6px;
  align-items:center;
}
.event-top-header.with-banner {
  border-radius: 0 0 6px 6px !important;
}

.color-gray {
  color: #909090 !important;
}

.border-radius {
  border-radius: 6px !important;
}
.border-radius-6 {
  border-radius: 6px !important;
}

.cart-container-title {
  border-radius: 6px 6px 0 0 !important;
}
.cart-container-title h1 {
  font-size: 32px !important;
  font-weight: bolder !important;
}

.cart-container-title label {
  font-size: 16px !important;
  font-weight: bold !important;
}

.cart-container-details {
  border-radius:  0 0 6px 6px !important;
  margin-top: 2px !important;
}
.banner-container {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  max-width: 1140px;
  overflow:hidden;
}
.banner-background {
  max-width: 100%;
  width: 100%;
  height: 147px;
  filter: blur(4px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.pos-banner {
  position: relative;
  width:100%;
  max-width: 855px;
  min-height:147px
}
.header-padding {
  padding: 25px 47px!important;
}
.header-padding h1{
  font-size: 32px;
  font-weight: bold;
}
@media only screen and (max-width: 992px) {
  #company-wrapper-2 {
    transform: translateY(-165px);
  }
  .stepper-wrapper::after,.stepper .step:first-of-type::before,.stepper .step:last-of-type::after{
    top:12.5px
  }
  .stepper .step:last-of-type::after{
    width:calc(50% - 12.5px)
  }
  .stepper .step .ball ,.stepper .step.step-current .ball{
    width: 25px;
    height: 25px;
  }
  .stepper .step.step-done .ball{
  width: 27px;
  height: 27px;
  }
  .stepper .step .ball-wrapper{
    width:30px
  }
  #company-wrapper{
    padding-bottom:65px
  }
  #company-footer{
    margin-top: -4rem;
  }
  .stepper{
    gap:0
  }
  .event-date-mobile {
    display: block !important;
  }
  .event-date-web {
    display: none !important;
  }
  .content-sidebar {
    margin-left: 0em;
    margin-top: 1em;
  }
  .info-card:last-of-type {
  padding-left: 0;
  }
  .info-card:nth-child(1) {
    border-right: 0 !important;
  }
  .info-card {
    padding: 17px !important;
  }
  .footer-info-blocks-border {
    display: block;
    margin: 10px 0;
  }
  .footer-info-blocks-border div {
    width: 82%;
    height: 1px;
    background-color: #EAEAEA;
    margin: auto;
  }
  .banner-container .banner-container {
    max-width: 992px;
  }
  .banner-container .pos-banner {
    width: 70%;
  }
  .header-padding {
    padding: 17px!important;
  }
  .footer-info-blocks {
    padding: 13px 17px !important;
  }
}
.languages {
  border-radius: 30px;
}
.languages .dropdown-toggle{
  position: relative;
  border-radius: 6px;
}
.languages .dropdown-toggle p,.languages .dropdown-item p{
  color:#0F273B!important;
}
.languages .dropdown-toggle::after{
  display:none
}
.languages .btn-secondary{
  background-color: #fff!important;
  height: 52px;
}
.languages .btn-secondary > div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.languages .btn-secondary > div > p{
  font-weight:600
}
.languages .btn-secondary > div img{
  margin: 0 7px 0 0;
}
.lang-flag, .selected-lang{
  width: 25px;
}
.speak{
  font-size: 11px;
  color: #fff!important;
  margin-right: 5px;
}
.dropdown-item{
  display: flex;
  align-items: center;
}


.dropdown-item{
  padding: 0.25rem .8rem!important;
}

.info-card td:nth-child(2) {
  padding-left: 15px;
}
@media only screen and (max-width: 770px) {
  #company-wrapper-2 {
    transform: translateY(-155px);
  }
  #company-wrapper{
    padding-bottom:55px
  }
  h1.event-title{
    margin-top: 0rem;
  }
  h1.event-title,.header-padding h1,.cart-container-title h1{
    font-size:20px!important;
  }
  .content-block.cart-container p,.cart-container-title label,.footer-info-blocks .info-card h3{
    font-size:12px!important;
  }
  #logo-container img{
    max-width: 120px;
    max-height: unset;
  }
  .languages .btn-secondary{
    height: 40px;
    font-size: 13px;
    font-weight:600
  }
  .languages .btn-secondary > div img{
    width:18px
  }
  .languages .btn-secondary > div svg{
    margin-left:9px!important;
    width: 11px;
  }
  .dropdown-item{
    font-size: 13px;
  }
  .stepper .step .label ,.footer-info-blocks .info-card p,p.event-date,p.event-venue{
    font-size: 10px;
  }
  p.event-date{
    margin-top:2px
  }
  .stepper .step{
    padding:0!important
  }
  #company-footer div.col.first img{
    max-width:100px !important;
    padding-left: 0 !important;
    }

  .footer-description {
    padding-inline-start: 28px;
    font-size:10px!important;
  }
    #company-header {
    padding: 15px 0;
  }
    #company-content {
    margin: 0px 0 115px 0;
  }
  .footer-info-blocks .info-card p svg{
    margin-right: 17px!important;
  }
  .info-card h3 svg{
    transform:scale(.7)
  }
  .info-card p svg{
    transform:scale(.8)
  }
}

@media only screen and (max-width: 576px) {
  .container{
    max-width:94%
  }
  #company-footer {
    margin-top: -8rem;
    margin-bottom: 6rem;
  }
  #company-wrapper-2 {
      transform: translateY(-190px);
  }
  #company-wrapper{
    padding-bottom:90px
  }
  #company-footer div.col{
    width:100%;
    border-left:0px;
    padding: 0 17px !important;
  }
  #company-footer div.row{
    flex-direction: column;
    width: 100%;
    margin: auto;
  }
  .footer-fairtix-border {
    display: block;
    margin: 30px 0px;
  }
  .footer-fairtix-border div {
    width: 85px;
    height: 1px;
    background-color: #EAEAEA;
  }
}

@media only screen and (max-width: 337px) {
  .content-sidebar {
    margin-left: -1em;
    margin-top: 1em;
  }
  .info-card {
    margin-top: 0em;
  }
}


@media screen and (max-width: 990px){
  .event-top-header.with-banner {
    border-radius: 0 0 10px 10px !important;
  }
  #company-nav {
    margin-bottom: 15px;
  }
}

@media (min-width: 200px) and (max-width: 992px) {
  .event-top-header {
    padding: 15px 17px !important;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
}

.container {
  padding-left: 47px;
  padding-right: 47px;
}

@media only screen and (max-width: 576px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 992px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 992px) and (min-width: 576px) {
  .container.company-footer {
    padding-left: 47px !important;
  }
}
</style>
