<template>
  <b-container class="p-0">
    <router-link v-if="order" :to="{name: 'ViewPointOfSale', params: {id: order.id, secret: order.secret}, query: backQuery}" class="back-btn text-white mb-3 d-block"><b-icon class="mr-1" icon="arrow-left"></b-icon>{{ $t('back') }}</router-link>
    <div v-if="order && pos && !saving">
      <div v-if="order">

        <gtm-page-view v-if="order && order.event && order.event.gtm_id" :gtm_id="order.event.gtm_id"></gtm-page-view>

        <template v-if="timeslots && timeslots.length > 0">
          <h3>{{ $t('order_pick_timeslot') }}</h3>
          <b-form-group>
            <b-select v-model="timeslotId" :options="timeslots" value-field="id" text-field="label"></b-select>
          </b-form-group>
          <div class="text-right">
            <b-button variant="success" size="lg" class="mb-4" @click="processOrder()">
              {{ $t('order_continue') }}
            </b-button>
          </div>
        </template>
        <template v-if="openingHours.length > 0">
          <div class="content-block cart-container timeslot-header header-padding">
            <b-row>
              <b-col>
                <h1 class="mb-0">{{ $t('shop_timeslot') }}</h1>
                <label class="mb-0">{{ $t('pos_timeslot_choose_the_time') }}</label>
              </b-col>
              <b-col cols="3" class="d-flex align-items-center justify-content-end">
                <order-timer v-if="order" :order="order"></order-timer>
              </b-col>
            </b-row>
          </div>
          <div class="content-block cart-container timeslot-calendar">
            <b-form-group>
              <b-calendar :start-weekday="1"
                          block
                          :hide-header="true"
                          :date-disabled-fn="dateDisabled"
                          :date-info-fn="dateInfo"
                          @context="getNewHours"
                          v-model="pickedDate"
              ></b-calendar>
            </b-form-group>
            <b-form-group v-if="pickedDate" class="order-pick-timeslot">
              <b-label>{{$t('order_pick_timeslot')}}</b-label>
              <b-form-radio-group v-model="selectedOption">
                <b-row id="timeslot-container">
                  <b-form-radio class="mb-3 timeslot-option" :class="(option.spots === 0 || (option.spots - option.spots_sold) < totalProductCount) ? 'disabled' : ''" :value="option" :disabled="option.spots === 0 || (option.spots - option.spots_sold) < totalProductCount" v-for="option in timeOptions" :key="option.day + option.opens_at">
                    {{ option.opens_at }} - {{ option.closes_at }}
                    <!-- ({{ option.spots_sold }} / {{ option.spots}}) -->
                  </b-form-radio>
                </b-row>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </template>

        <PosCartPlaceOrder :disabled="!selectedOption" @continue="makeTimeslotForSelectedOption()"/>
      </div>
    </div>
    <b-row v-else>
      <b-col class="text-center">
        <h1>{{ $t('order_loading') }}</h1>
        <b-spinner style="width: 10em; height: 10em;"></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '../api'
import moment from 'moment'
import {mapState} from 'vuex'
import GtmPageView from '@/components/GtmPageView'
import PosCartPlaceOrder from '@/components/PosCartPlaceOrder.vue';
import OrderTimer from '@/components/OrderTimer.vue';

export default {
  components: {
    OrderTimer,
    PosCartPlaceOrder,
    GtmPageView
  },
  data() {
    return {
      saving: false,
      timeslots: [],
      timeslotId: null,
      openingHours: [],
      pickedDate: null,
      selectedOption: null,
      totalProductCount: null
    }
  },
  methods: {
    getNewHours(context){
      let start = moment(context.activeYMD).startOf('month').format('YYYY-MM-DD')
      let end =  moment(context.activeYMD).endOf('month').format('YYYY-MM-DD')
      api.openingHour().getByOrder(
          this.$route.params.id,
          this.$route.params.secret,
          start,
          end
      ).then(openingHours => {
        if (openingHours.length === 0) {
          // also no opening hours, let's redirect
          this.$router.push({
            name: 'OrderAdditionalProducts',
            params: this.$route.params
          })
          return
        }
        this.openingHours = openingHours
      })
    },
    dateDisabled(ymd) {
      return this.openingHours.filter(openingHour => {
        return openingHour.day === ymd
      }).length === 0
    },
    dateInfo(ymd) {
      let open = this.openingHours.filter(openingHour => {
        return openingHour.day === ymd
      }).length > 0

      return open ? 'table-success' : 'table-danger'
    },
    makeTimeslotForSelectedOption(){
      if(this.order.payment_status === 'paid' || this.order.payment_status === 'pending') {
        this.$router.push({name: 'ViewPointOfSale', params: {url: this.pos.url}});
        this.$store.commit('errors', [this.$t('order_already_paid')]);
        return;
      }
      if(this.selectedOption){
        this.$store.commit('setCartTimeslot', this.selectedOption.day + ' ' + this.selectedOption.opens_at + ' - ' + this.selectedOption.closes_at)      
      }
      if(!this.selectedOption.timeslot_id){
        api.openingHour().makeTimeslotForOrder(this.$route.params.id, this.$route.params.secret, this.selectedOption).then(timeslot => {
          this.timeslotId = timeslot.id
          this.processOrder()
        })
      } else {
        this.timeslotId = this.selectedOption.timeslot_id
        this.processOrder()
      }
    },
    processOrder() {
      this.saving = true
      let order = Object.assign(this.order, {timeslot_id: this.timeslotId})
      order.customer = null;
      if(order.payment_status === 'paid' || order.payment_status === 'pending') {
        this.$router.push({name: 'ViewPointOfSale', params: {url: this.pos.url}});
        this.$store.commit('errors', [this.$t('order_already_paid')]);
        return;
      }
      api.order().save(order).then((order) => {
        this.$store.commit('setOrder', order)
        if(this.campaign) {
          this.$router.push({name: 'OrderEnterPersonalDetails', params: this.$route.params, query: {ft_campaign_name: this.campaign.slug}})
        }
        else {
          this.$router.push({name: 'OrderEnterPersonalDetails', params: this.$route.params})
        }
      }).catch((error) => {
        if(error?.response?.data[0]?.code === 'order_already_paid') {
          this.$router.push({name: 'ViewPointOfSale', params: {url: this.pos.url}});
        }
        else {
          this.$store.commit('errors', ['error_try_again'])
        }
        this.saving = false
      })
    }
  },
  computed: {
    timeOptions(){
        if(!this.pickedDate) {
          return []
        }

        return this.openingHours.filter((openingHour) => {
          return this.pickedDate === openingHour.day
        })
    },
    backQuery() {
      if(this.campaign) {
        return {ft_campaign_name: this.campaign.slug};
      }
      return {};
    },
    ...mapState(['cart', 'event', 'order', 'pos', 'order', 'campaign'])
  },
  created() {
    document.title = this.$t('pos_timeslot_title')
    api.order().getBySecret(this.$route.params.id, this.$route.params.secret).then((order) => {
      this.$store.commit('setOrder', order)
    })

    let starts_at = moment().format('YYYY-MM-DD')
    let ends_at = moment().endOf('month').format('YYYY-MM-DD')

    api.openingHour().getByOrder(
        this.$route.params.id,
        this.$route.params.secret,
        starts_at,
        ends_at
    ).then(openingHours => {

      if(openingHours.length > 0 ) {
        this.openingHours = openingHours
        return
      }

      // Check if we have timeslots
      api.timeslot().getByOrder(this.$route.params.id, this.$route.params.secret).then((timeslots) => {
        if(this.timeslots.length === 0) {
          // also no timeslots, continue
          if(this.campaign) {
            this.$router.push({name: 'OrderEnterPersonalDetails', params: this.$route.params, query: {ft_campaign_name: this.campaign.slug}})
          }
          else {
            this.$router.push({name: 'OrderEnterPersonalDetails', params: this.$route.params})
          }
          return
        }
        this.timeslots = timeslots.map((timeslot) => {
          let start = moment(timeslot.starts_at)
          let end = moment(timeslot.ends_at)

          // Not same day, so print dates
          if (start.diff(end, 'days') !== 0) {
            timeslot.label = start.format('DD-MM HH:mm') + ' - ' + end.format('DD-MM HH:mm')
          } else {
            timeslot.label = start.format('HH:mm') + ' - ' + end.format('HH:mm')
          }

          return timeslot
        })
      })
    })

    if(this.cart){
      this.cart.products.forEach(p => {
        this.totalProductCount += p.amount
      })
    }

  }
}
</script>
<style>
.order-pick-timeslot{
  margin-bottom:0!important
}
.order-pick-timeslot > div{
  display:flex;
  align-items:center;
  gap:1rem;
}
.order-pick-timeslot .timeslot-option.custom-control{
  margin-bottom:0!important
}
@media(max-width:768px){
  .timeslot-calendar .b-calendar *{
    font-size:12px!important
  }
  .order-pick-timeslot > div b-label,.order-pick-timeslot .custom-control-label{
    font-size:12px
  }
}
</style>
<style scoped>
.header-padding{
  padding:35px!important
}
.timeslot-option.custom-control{
  padding-left:0px
}
.timeslot-option /deep/ .custom-control-label::before{
  visibility:hidden !important;
}
.timeslot-option /deep/ .custom-control-label{
  padding:10px
}
.timeslot-option /deep/ .custom-control-input+label{
  border:1px solid lightgrey;
  border-radius:5px;
  cursor:pointer
}
.timeslot-option.disabled /deep/ .custom-control-input+label{
  background-color:lightgrey;
  color:grey;
  cursor:default;
  border-color:lightgrey
}
.timeslot-option /deep/ .custom-control-input:checked+label{
  background-color:#28a745;
  color:#FFF;
}
.timeslot-option /deep/ .custom-control-input:checked+label::after{
  display:none
}
#timeslot-container{
  padding:0 15px
}

.timeslot-header {
  border-radius: 10px 10px 0 0 !important;
  padding:42px 47px!important
}
.timeslot-header h1 {
  color: #0A2336 !important;
  font-size: 32px !important;
  font-weight: bolder !important;
}

.timeslot-header label {
  color: #909090 !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
.timeslot-calendar {
  border-radius:  0 0 10px 10px !important;
  margin-top: 1px !important;
}
@media (max-width:768px){
  .header-padding,.content-block,.timeslot-header{
    padding:20px!important
  }
  .timeslot-header h1 {
    font-size: 20px !important;
  }
  .timeslot-header label {
    font-size: 12px !important;
  }
}
</style>
