<template>
  <b-container class="p-0">
    <router-link v-if="$store.state.pos" :to="{name: hasTimeslots ? 'OrderSelectTimeslot' : 'ViewPointOfSale', params: {url: $store.state.pos.url}, query: backQuery}" class="text-white mb-3 d-block back-btn"><b-icon icon="arrow-left"></b-icon> {{ $t('back') }}</router-link>
    <div v-if="order && pos && !saving">
      
      <gtm-page-view v-if="order && order.event && order.event.gtm_id" :gtm_id="order.event.gtm_id"></gtm-page-view>

      <div class="content-block cart-container personal-details-header header-padding">
        <b-row>
          <b-col>
            <h1 class="mb-0">{{ $t('pos_personal_details') }}</h1>
            <label class="mb-0">{{ $t('pos_personal_details_complete_purchase') }}</label>
          </b-col>
          <b-col cols="3" class="d-flex align-items-center justify-content-end">
            <order-timer v-if="order" :order="order"></order-timer>
          </b-col>
        </b-row>
      </div>
      <div v-if="order" class="content-block cart-container personal-details-container">
        <customer-form v-if="loadingDone" ref="form" level="order" v-model="customer" :fields="customerFields" :hideTitle="true"></customer-form>
        <customer-information-form ref="customer-information-form" v-model="answers"
                                   :questions="questions"></customer-information-form>
        <b-row>
          <b-col class="pos-terms">
            <span v-if="pos.terms_conditions_url && pos.terms_conditions_url !== ''" class="terms-conditions-text" v-html="$t('cart_terms_conditions2', {url: 'https://' + pos.company.domain + '/terms-conditions', url2: pos.terms_conditions_url, name: pos.company.name})"></span>
            <span v-else class="terms-conditions-text" v-html="$t('cart_terms_conditions', {url: 'https://' + pos.company.domain + '/terms-conditions'})"></span>
          </b-col>
        </b-row>
      </div>
      <PosCartPlaceOrder @continue="processOrder()"/>
    </div>
    <b-row v-else>
      <b-col class="text-center">
        <h1>{{ $t('order_loading') }}</h1>
        <b-spinner style="width: 10em; height: 10em;"></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'
import CustomerForm from '@/components/CustomerForm'
import {deepClone, getLocalStorage, setLocalStorage, getCookie} from "@/functions";
import CustomerInformationForm from "@/components/CustomerInformationForm.vue";
import GtmPageView from "@/components/GtmPageView.vue";
import PosCartPlaceOrder from '@/components/PosCartPlaceOrder'
import OrderTimer from '@/components/OrderTimer.vue';

export default {
  components: {
    OrderTimer,
    CustomerInformationForm,
    CustomerForm,
    GtmPageView,
    PosCartPlaceOrder
  },
  data() {
    return {
      saving: false,
      customerFields: [],
      customer: {}, 
      customQuestions: [],
      answers: {},
      loadingDone: false,
      cookieCategories: null
    }
  },
  methods: {
    processOrder() {
      if(this.order.payment_status === 'paid' || this.order.payment_status === 'pending') {
        this.$router.push({name: 'ViewPointOfSale', params: {url: this.pos.url}});
        this.$store.commit('errors', [this.$t('order_already_paid')]);
        return;
      }

      this.$store.commit('errors', [])

      let customerValid = this.$refs['form'].isValid();
      let customerInformationValid = this.$refs['customer-information-form'].isValid();

      if (!customerValid || !customerInformationValid) {
        return
      }

      this.saving = true
      let order = Object.assign(this.order, {customer: this.customer})

      api.order().save(order).then((order) => {
        this.$store.commit('setOrder', order)

        const cookieCategories = getCookie("ft_cookieCategories");
        if (cookieCategories) {
          let tmpCookieCategories = JSON.parse(cookieCategories);
          if(tmpCookieCategories.local_information){
            setLocalStorage('booker_first_name', this.customer.first_name);
            setLocalStorage('booker_last_name', this.customer.last_name);
            setLocalStorage('booker_email', this.customer.email);
          }
        }

        if (this.customQuestions.length > 0) {
          api.customerInformation().submitByOrder(this.order.id, this.order.secret, this.answers).then(() => {})
        }
        if(this.campaign) {
          this.$router.push({name: 'OrderSelectPaymentMethod', params: this.$route.params, query: {ft_campaign_name: this.campaign.slug}})
        }
        else {
          this.$router.push({name: 'OrderSelectPaymentMethod', params: this.$route.params})
        }
      }).catch((error) => {
        this.saving = false
        if(error?.response?.data[0]?.code === 'order_already_paid') {
          this.$router.push({name: 'ViewPointOfSale', params: {url: this.pos.url}});
          return;
        }
        this.$store.commit('errors', ['error_try_again'])
      })
    }
  },
  computed: {
    questions() {
      const products = deepClone(this.order.order_products).map(orderProduct => {
        return orderProduct.product_id;
      })
      return this.customQuestions.filter(question => {
        if(question.where_to_show === 'order') {
          if(question.show_at_specific_prod) {
            return question.products.some(product => {
              return products.includes(product.product_id);
            })
          }
          return true;
        }
        return false;
      });
    },
    backQuery() {
      if(this.campaign) {
        return {ft_campaign_name: this.campaign.slug, back: ''};
      }
      return {back: ''};
    },
    hasTimeslots() {
      if (!this.order || !this.order.event) {
        return false
      }
      return this.order.event.timeslots.length > 0 || (this.order.event.opening_hours && this.order.event.opening_hours.length > 0)
    },
    ...mapState(['order', 'pos', 'cart', 'campaign'])
  },
  created() {
    if(document.body.contains(document.getElementById('company-header'))){
      document.getElementById("company-header").scrollIntoView();
    }
    document.title = this.$t('pos_personal_details_title')
     
    let apiCallOne = api.customerInformation().getFieldsByOrder(this.$route.params.id, this.$route.params.secret).then((customerFields) => {
      this.customerFields = customerFields
    })
    api.customerInformation().getByOrder(this.$route.params.id, this.$route.params.secret).then((customQuestions) => {
      this.customQuestions = customQuestions
    })
    
    let apiCallTwo = api.order().getBySecret(this.$route.params.id, this.$route.params.secret).then((order) => {
      if (order.customer) {
        if(order.customer.address) {
          order.customer = Object.assign(order.customer, {...order.customer.address});
        }
        this.customer = order.customer
      } else {
        this.customer = {}
      }
      this.$store.commit('setOrder', order)
    })

    Promise.all([apiCallOne, apiCallTwo]).then(() => {
      this.customerFields.forEach((field) => {
        if( typeof this.customer[field.field] === 'undefined' && getLocalStorage('booker_' + field.field) !== null) {
          this.customer[field.field] = getLocalStorage('booker_' + field.field);
        }
      })
      this.loadingDone = true;
    })
  }
}
</script>

<style scoped>
.terms-conditions-text{
  font-size:12px;
}
.personal-details-header {
  border-radius: 10px 10px 0 0 !important;
  padding:42px 47px!important
}

.personal-details-header label {
  font-size: 16px !important;
  font-weight: bold !important;
}
.personal-details-container {
  border-radius:  0 0 10px 10px !important;
  margin-top: 1px !important;
}
@media only screen and (max-width: 992px) {
  .personal-details-container {
    padding: 17px !important;
  }
}
@media only screen and (max-width: 768px) {
  .personal-details-header label {
    font-size: 12px !important;
  }
   .personal-details-container > h2{
    font-size: 17px !important;
  }
  .terms-conditions-text{
   font-size:10px;
  }
  .personal-details-header {
    padding:17px!important
  }
}
</style>
