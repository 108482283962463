import { render, staticRenderFns } from "./OrderSelectPaymentMethod.vue?vue&type=template&id=13810052&scoped=true"
import script from "./OrderSelectPaymentMethod.vue?vue&type=script&lang=js"
export * from "./OrderSelectPaymentMethod.vue?vue&type=script&lang=js"
import style0 from "./OrderSelectPaymentMethod.vue?vue&type=style&index=0&id=13810052&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13810052",
  null
  
)

export default component.exports