<template>
  <b-container class="p-0">

    <b-row v-if="errors[0] !== undefined && errors[0] === 'event_not_live'">
      <b-col class="content-block text-center">
        <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 463.43" style="max-width: 100px" class="mt-4 mb-4">
          <path d="M189.46 44.02c34.26-58.66 99.16-58.77 133.24.12l.97 1.81 175.27 304.4c33.71 56.4-1.2 113.76-66.17 112.96v.12H73.53c-.9 0-1.78-.04-2.66-.11-58.34-.79-86.64-54.22-61.9-106.84.39-.85.82-1.67 1.28-2.46l-.04-.03 179.3-309.94-.05-.03zm50.32 302.4c4.26-4.13 9.35-6.19 14.45-6.56 3.4-.24 6.8.29 9.94 1.48 3.13 1.19 6.01 3.03 8.39 5.41 6.92 6.91 8.72 17.38 4.64 26.16-2.69 5.8-7.08 9.7-12.11 11.78-3.03 1.27-6.3 1.84-9.56 1.76-3.27-.08-6.49-.82-9.41-2.18-5.02-2.33-9.3-6.43-11.7-12.2-2.65-6.36-2.27-12.96.63-19.15 1.15-2.46 2.75-4.81 4.73-6.5zm33.86-47.07c-.8 19.91-34.51 19.93-35.28-.01-3.41-34.1-12.13-110.53-11.85-142.58.28-9.87 8.47-15.72 18.94-17.95 3.23-.69 6.78-1.03 10.35-1.02 3.6.01 7.16.36 10.39 1.05 10.82 2.3 19.31 8.39 19.31 18.45l-.05 1-11.81 141.06z" fill="#FFA500"/>
        </svg>
        
        <h1>{{ $t('event_passed') }}</h1>
        <p>{{ $t('pos_will_automatically_redirect', {seconds: passedRedirectTimer}) }}</p>
        <b-button id="btn-pos-redirect" class="mt-2 mb-4" variant="success" @click="$router.push({name: 'Index'})">{{ $t('pos_redirect_now') }}</b-button>
      </b-col>
    </b-row>

    <b-row v-if="pos">
      
      <gtm-page-view v-if="event && event.gtm_id" :gtm_id="event.gmt_id"></gtm-page-view>

      <b-col v-if="show">
        <div v-if="event.description_location && event.description_location === 'top'" class="content-block cart-container header-padding information-toggle  border-radius margin-bottom-custom">
          <h3 class="pos-primary-color cursor-pointer font-weight-bold information-header mb-0" @click="isEventDescriptionOpened = !isEventDescriptionOpened" v-b-toggle.event-description>
            <svg height="20" viewBox="0 0 48 48" class="mr-2" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48h-48z" fill="none"/><path d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z"/></svg> 
            {{ $t('pos_important_information') }}
            <b-icon :icon="isEventDescriptionOpened ? 'chevron-up' : 'chevron-down'" class="ml-2" width="10" height="10"></b-icon>
          </h3>

          <b-collapse id="event-description" accordion="my-accordion" role="tabpanel">
            <b-card-body class="pt-2 p-0">
              <b-card-text><div v-html="event.description" class="desc-info pos-primary-color"></div></b-card-text>
            </b-card-body>
          </b-collapse>
        </div>
        <div class="content-block cart-container border-radius header-padding" :class="{'group-name-is-default': $store.state.posFirstGroupIsDefault}">
          <b-row>
            <b-col cols="9">
              <h1 class="pos-primary-color mb-1">{{ $t('view_pos_cart_title') }}</h1>
              <p class="pos-description-color font-weight-bold mb-0 pb-0">{{ $t('pos_buy_your_tickets') }}</p>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-end" cols="3" v-if="event.minimum_age">
              <span class="minimum-age pos-primary-color pos-primary-color-border">
                {{ event.minimum_age }}+
              </span>
            </b-col>
          </b-row>
        </div>
        <product-list></product-list>
        <PosCartPlaceOrder @placeOrder="order()" @continue="order()" :disabled="!this.cart.products || this.cart.products.length === 0 || ( event.minimum_age !== '' && event.age_confirmation_checkbox && !age_confirmation_checkbox)" />
         
         <!-- {{ 'TEST: ' + (event.minimum_age !== '') + ' @ ' + event.age_confirmation_checkbox + ' @ ' + age_confirmation_checkbox }} -->
        <div v-if="event.minimum_age && event.age_confirmation_checkbox" class="content-block cart-container border-radius margin-top-custom">
          <b-form-group align="end" class="mb-0">
            <b-row>
              <b-col sm="12" md="12">
                <div>
                  <b-checkbox v-model="age_confirmation_checkbox" class="pos-age-confirmation">{{ $t('pos_age_confirmation', event) }}</b-checkbox>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
        <div v-if="event.description_location && event.description_location === 'bottom'" class="content-block information-toggle cart-container header-padding border-radius margin-top-custom">
          <h3 class="pos-primary-color cursor-pointer font-weight-bold information-header mb-0" @click="isEventDescriptionOpened = !isEventDescriptionOpened" v-b-toggle.event-description>
            <svg height="25" viewBox="0 0 48 48" class="mr-2" width="25" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48h-48z" fill="none"/><path d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z"/></svg>
            {{ $t('pos_important_information') }}
            <b-icon :icon="isEventDescriptionOpened ? 'chevron-up' : 'chevron-down'" class="ml-2" width="13" height="13"></b-icon>
          </h3>

          <b-collapse id="event-description" accordion="my-accordion" role="tabpanel">
            <b-card-body class="pt-2 p-0">
              <b-card-text><div v-html="event.description" class="desc-info pos-primary-color"></div></b-card-text>
            </b-card-body>
          </b-collapse>
        </div>
      </b-col>
      <b-col v-else>
        <div class="content-block cart-container border-radius">
          <div class="loading-logo text-center">
            <b-img :src="require('./../assets/fairtix.gif')"/>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-else-if="errors.length === 0">
      <b-col class="text-center">
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'
import ProductList from '@/components/ProductList'
import GtmPageView from '@/components/GtmPageView'
import PosCartPlaceOrder from '@/components/PosCartPlaceOrder'

export default {
  name: 'ViewPointOfSale',
  components: {
    ProductList,
    GtmPageView,
    PosCartPlaceOrder
  },
  data() {
    return {
      amounts: {},
      processing: false,
      error: null,
      passedRedirectTimer: 4,
      show: false,
      age_confirmation_checkbox: false,
      gtm_id: null,
      redirectInterval: null,
      isEventDescriptionOpened: false
    }
  },
  methods: {
    order() {
      const products = this.cart.products.map((product) => {
        let manual_price = false
        if(product.product_type_id === this.donation_product_id){
          manual_price = true
        }
        return {
          product_id: product.id,
          amount: product.amount,
          price: product.price,
          manual_price: manual_price,
          is_disabled: product.is_disabled,
        }
      }).filter(p => !p.is_disabled);

      if(products.length === 0) {
        this.$store.commit('errors', ['shop_no_products_selected'])
        return
      }

      if(this.$route.query.back !== undefined && this.$store.state.order) {
        this.processing = true;
        api.order().updateProducts(this.$store.state.order,products).then(order => {
          this.redirectAfterCreate(order);
        })
        return;
      }
      this.processing = true
      const campaign = this.$route.query.ft_campaign_name
      let source = this.$route.query.utm_campaign
      api.order().create(this.pos.id, products, campaign, this.cart.campaignDiscount, source, this.cart.discountCode).then((order) => {
        this.redirectAfterCreate(order);
      }).catch(() => {
        this.processing = false
        // this.$store.commit('errors', [error.response.error.error])
      })
    },
    redirectAfterCreate(order) {
      const route = this.hasTimeslots ? 'OrderSelectTimeslot' : 'OrderEnterPersonalDetails';
      if(this.campaign) {
        this.$router.push({name: route, params: {id: order.id, secret: order.secret}, query: {ft_campaign_name: this.campaign.slug}})
      }
      else {
        this.$router.push({name: route, params: {id: order.id, secret: order.secret}})
      }
    }
  },
  computed: {
    hasTimeslots() {
      if (!this.event) {
        return false
      }
      return this.event.timeslots.length > 0 || (this.event.opening_hours && this.event.opening_hours.length > 0)
    },
    ...mapState(['cart', 'errors', 'pos', 'event', 'donation_product_id', 'campaign'])
  },
  created() {
    if(this.$route.query.back !== undefined && this.$store.state.order) {
      this.show = true;
      return;
    }
    setTimeout(() => {
      this.show = true;
    }, 1300)
    api.pos().getByUrl(this.$route.params.url).then((pos) => {
      if(this.$route.query.ft_campaign_name) {
        api.campaign().findByDomain(this.$route.query.ft_campaign_name, pos.event.id).then(response => {
          if(response.campaign) {
            this.$store.commit('setCampaign', response.campaign);
          }
        })
      }
      this.$store.commit('setPos', pos)
      this.$store.commit('setLanguage', pos.language)

      document.title = this.$t('view_pos_title', {'event_name':this.pos.event.name});
      document.querySelector('head').innerHTML += '<meta name="robots" content="noindex">'

      // And we can check against the current stock in the backend
      this.cart.products.forEach(product => {
        this.amounts[product.id] = product.amount
      })

      // apply custom styling
      let customCss = document.getElementById('custom-css')
      if (customCss) {
        customCss.remove()
      }
      document.querySelector('head').innerHTML += '<style id="custom-css">' + pos.custom_css + '</style>'
    }).catch((error) => {
      if (error.response.data.length > 0 && error.response.data[0] === 'pos_not_found') {
        this.$router.push({
          name: 'NotFound'
        })
        return
      }
      if(error.response.data.length && error.response.data[0].code === 'event_not_live') {
        this.redirectInterval = setInterval(() => {
          if(this.passedRedirectTimer) {
            this.passedRedirectTimer--;
          }
        }, 1000)
        setTimeout(() => {
          this.$router.push({name: 'Index'})
        }, 4000)
      }
      else {
        this.$store.commit('errors', error.response.data)
      }
    })
  }
}
</script>
<style>
.desc-info > p{
  margin-bottom:0;
  font-size:.8rem;
}
</style>
<style scoped>
#pos-image{
  width:100%;
  max-height:250px;
  object-fit:cover
}

.loading-logo {
  height: 100%;
  min-height: 200px;
  position: relative;
}
.loading-logo img {
  height: 200px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.content-block.cart-container p{
  font-size:16px;
}
.content-block.cart-container h2{
  font-weight:bold;
  font-size:1.5rem
}

.price {
  font-size: 1.5em;
  line-height: 50px;
}

.group-title {
  background: #efefef;
  color: gray;
  padding: 0.5em;
  font-size: 1.2em;
  text-transform: uppercase;
}

.product-details {
  padding: 0.5em;
}

.product-details h3 {
  font-size: 1em;
}

.hint-container {
  text-align: center;
}

.hint {
  font-size: 0.8em
}

.content-block.cart-container /deep/ select.custom-select{
  font-size:16px !important
}

#btn-pos-redirect{
  font-size: 1.4em;
  border-radius:50px;
  padding:12px 35px
}

.nopadding{
  padding:0px
}

.clickable-text{
  cursor:pointer;
  text-decoration:underline;
  font-size:14px
}

.sidebar-order-button{
  display:block
}

.margin-top-custom{
  margin-top:35px
}
.margin-bottom-custom{
  margin-bottom:35px
}

@media only screen and (max-width: 990px) {
  .sidebar-order-button{
    display:none
  }
  .sidebar-container{
    padding-left:0px
  }
  #pos-image{
    max-height:none
  }
  .margin-top-custom{
    margin-top:15px
  }
  .margin-bottom-custom{
    margin-bottom:15px
  }
}

.group-name-is-default {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-bottom: 1px !important;
  padding:42px 47px!important
}

.minimum-age {
  font-size: 16px !important;
  border: 1px solid;
  border-radius: 6px;
  padding: 11px 8px !important;
  font-weight: bold;
}
.information-header {
  font-weight:600;
  font-size: 16px !important;
}
.information-toggle {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}
@media only screen and (max-width: 770px) {
  .minimum-age {
    font-size: 14px !important;
    padding: 8px 6px !important;
  }
  .information-header {
    font-size: 12px !important;
  }
  .information-header svg:first-of-type{
    width:15px;
    height:15px;
  }
  .information-header svg:last-of-type{
    width:10px;
    height:10px;
  }
  .group-name-is-default {
    padding:17px!important
  }
}
</style>

<style>
.pos-age-confirmation .custom-control-label {
  font-size: 14px !important;
}
</style>
