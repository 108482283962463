<template>
  <div>
    <b-row v-for="question in questions" :key="`q` + question.id" class="additional-questions">
      <b-col>
        <MaterialInput
            v-if="question.question_type === 'input'"
            v-model="answers[question.id]"
            :label="(question.mandatory) ? question.question + '*' : question.question"
            :state="validated ? errors.indexOf(question.id) === -1 : null"
            :disabled="disabled"
            @change="reValidate()"/>
        <b-form-group v-else :label="(question.mandatory) ? question.question + '*' : question.question">
          <b-select v-if="question.question_type === 'select'"
                    :disabled="disabled"
                    v-model="answers[question.id]"
                    :options="question.answers"
                    :state="validated ? errors.indexOf(question.id) === -1 : null"
                    @change="reValidate()"
                    text-field="answer"
                    value-field="answer"></b-select>
          <b-form-checkbox-group v-if="question.question_type === 'checkboxes'"
                                 :disabled="disabled"
                                 v-model="answers[question.id]"
                                 :options="question.answers"
                                 :state="validated ? errors.indexOf(question.id) === -1 : null"
                                 @change="reValidate()"
                                 text-field="answer"
                                 value-field="answer"></b-form-checkbox-group>
          <b-form-radio-group v-if="question.question_type === 'radio-buttons'"
                              :disabled="disabled"
                              v-model="answers[question.id]"
                              :state="validated ? errors.indexOf(question.id) === -1 : null"
                              @change="reValidate()"
                              :options="question.answers"
                              text-field="answer"
                              value-field="answer">
            <b-form-invalid-feedback :state="validated ? errors.indexOf(question.id) === -1 : null">
              {{ $t('errors_please_select') }}
            </b-form-invalid-feedback>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import MaterialInput from './MaterialInput.vue';

export default {
  components: {MaterialInput},
  props: ['value', 'questions', 'disabled'],
  data() {
    return {
      errors: [],
      validated: false,
      answers: {}
    }
  },
  methods: {
    validate() {
      this.validated = true
      this.errors = []
      this.questions.forEach(question => {
        if(question.mandatory){
          let f = question.id
          if (!this.answers[f]) {
            this.errors.push(f)
          } else {
            if (this.answers[f].length === 0) {
              this.errors.push(f)
            }
          }
        }
      })
    },
    reValidate() {
      if (this.validated && !this.isValid) {
        this.validate()
      }
    },
    reset() {
      this.errors = []
      this.validated = false
    },
    isValid() {
      this.validate()
      return this.validated && this.errors.length === 0
    },
  },
  watch: {
    answers() {
      this.$emit('input', this.answers)
    },
    value() {
      this.answers = this.value
    }
  },
  mounted() {
    this.answers = this.value
  }
}
</script>
<style>
@media(max-width:992px){
  .additional-questions .form-group{
    font-size:12px!important
  }
}
.additional-questions .custom-control-label{
  display: flex;
  align-items: center;
}
</style>