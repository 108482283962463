<template>
  <div id="cart">
    <h3 class="cart-title pos-secondary-color">{{ $t('cart_title') }}</h3>
    <div v-if="calculatedCart"></div>
    <div  v-for="product in calculatedCart.products" :key="`cart_product` + product.id" class="cart-row pos-bg-secondary-color border-radius">
      <b-row>
        <b-col class="cart-product-details pos-primary-color">
          {{ product.amount + `x` }} {{ product.name }}
        </b-col>
        <b-col class="cart-product-details pos-primary-color text-right">
          {{ product.total | currency }}
        </b-col>
      </b-row>
    </div>
    <div v-if="calculatedCart" class="cart-total-container">
      <b-row>
        <b-col class="cart-totals pos-secondary-color">{{ $t('cart_sub_total') }}:</b-col>
        <b-col class="cart-totals pos-secondary-color text-right">{{ calculatedCart.productTotal | currency }}</b-col>
      </b-row>
      <b-row v-if="calculatedCart.processingFee > 0 || calculatedCart.paymentFee > 0">
        <b-col class="cart-totals pos-secondary-color">{{ $t('cart_ticket_fee') }}:</b-col>
        <b-col class="text-right cart-totals pos-secondary-color">{{ calculatedCart.processingFee + calculatedCart.paymentFee | currency }}</b-col>
      </b-row>
      <b-row v-if="calculatedCart.discount">
        <b-col class="cart-totals pos-secondary-color">{{ $t('cart_discount', cart.discountCode) }}:</b-col>
        <b-col class="text-right cart-totals pos-secondary-color">{{ calculatedCart.discount | currency }}</b-col>
      </b-row>
      <b-row v-if="cart.campaignDiscount">
        <b-col class="cart-totals pos-secondary-color">{{ $t('cart_discount', {code: ''}) }}:</b-col>
        <b-col class="text-right cart-totals pos-secondary-color">{{ '-' + cart.campaignDiscount | currency }}</b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import api from "@/api";

export default {
  data(){
    return {
      discountCode: null,
      discountCodeError: null,
      timeslot: null
    }
  },
  methods: {
    calculateVatFromEx(price, percentage){
      let vat = price * (percentage / 100)
      return vat
    },
    calculateVat(price, percentage) {
      let excl = price / (1 + percentage / 100)
      let vat = price - excl
      return vat
    },
    claimDiscountcode() {
      this.discountCodeError = null
      if(!this.discountCode){
        this.discountCodeError = false
        return
      }
      api.discountcode().getByOrder(this.$route.params.id, this.$route.params.secret, this.discountCode).then(discountCode => {
        this.$store.commit('setCartDiscountCode', discountCode)
      })
    },
    ...mapActions(['loadCompany']),
    ...mapMutations(['setCartTotal', 'setCartTotalWithoutPM', 'setProcessingFee'])
  },
  computed: {
    calculatedCart() {

      if (!this.event) {
        console.log('Event not in state!')
        return null
      }
      if (!this.company) {
        console.warn('Company not in state, maybe still loading!')
        return null
      }

      if (!this.cart) {
        console.warn('Cart not in state!')
        return null
      }

      let productTotal = 0
      let paymentFee = 0
      let processingFee = 0
      let totalProductCount = 0
      let campaignDiscount = 0;

      this.cart.products.forEach(p => {
        productTotal += p.amount * p.price
        totalProductCount += p.amount
      })

      this.cart.products.forEach( product => {
        if(product.service_fee){
          processingFee += (product.service_fee * product.amount)
        }
      })

      this.cart.products.forEach( product => {
        campaignDiscount += ((product.price - product.discount_price) * product.amount)
      })
      this.$store.commit('setCartCampaignDiscount', campaignDiscount)

      // let total = productTotal + paymentFee + processingFee
      let total = productTotal

      let discount = 0
      if(this.cart.discountCode) {
        // if type is per order
        if (this.cart.discountCode.apply_type === 'order') {
          let productsTotal = 0;
          this.cart.products.forEach(p => {
            if (this.cart.discountCode.products && this.cart.discountCode.products.length) {
              if (this.cart.discountCode.products.includes(p.id)) {
                productsTotal += p.amount * p.discount_price
              }
            }
            else {
              productsTotal += p.amount * p.discount_price
            }
          })
          if (this.cart.discountCode.type === 'percentage') {
            discount = 0 - Math.round(((productsTotal * this.cart.discountCode.amount / 100) + Number.EPSILON) * 100) / 100
          }
          else if (this.cart.discountCode.type === 'value') {
            discount = 0 - this.cart.discountCode.amount
          }
        } else { // if type is per ticket
          for (const p of this.cart.products) {
            if (this.cart.discountCode.products && this.cart.discountCode.products.length) {
              if(!this.cart.discountCode.products.includes(p.id)) {
                continue;
              }
            }
            if (this.cart.discountCode.type === 'percentage') {
              discount -= ((Math.round(((p.discount_price * this.cart.discountCode.amount / 100) + Number.EPSILON) * 100) / 100) * p.amount)
            }
            else if (this.cart.discountCode.type === 'value') {
              discount -= (this.cart.discountCode.amount * p.amount)
            }
          }
        }
      }

      if(campaignDiscount) {
        total -= campaignDiscount;
      }

      total += discount

      if (total < 0) {
        total = 0
      }

      let paymentFeeVat = 0

      /**
       * Payment method calculation:
       * Payment fee fixed is already inclusive VAT
       * Payment fee percentage is without VAT
       *
       * Calculation:
       * 1) Add the payment fee fixed
       * 2) Calculate fee percentage = (total products + total ticket fee + fixed fee) * percentage
       * 3) Add VAT for the company to the total
       * 4) Round the total payment fee
       */
      if (this.cart.paymentMethod) {

        // Payment Fee fixed is inclusive VAT
        paymentFee = this.cart.paymentMethod.fee_fixed

        // Calculate the fee that is needed for the percentage
        let feePercentage = 0
        if (this.cart.paymentMethod.fee_percentage > 0) {
          feePercentage = (processingFee + total + this.cart.paymentMethod.fee_fixed) * (this.cart.paymentMethod.fee_percentage / 100)
          paymentFee += feePercentage
        }

        // Add the VAT to the payment fee (only from the percentage)
        if(this.company.vat_percentage && paymentFee){
          paymentFeeVat = parseFloat( this.calculateVatFromEx(feePercentage, this.company.vat_percentage) )
          if(paymentFeeVat){
            paymentFee += paymentFeeVat
          }
        }

        // Rounds to two decimals
        if(paymentFee){
          paymentFee = Math.round((paymentFee + Number.EPSILON) * 100) / 100
        }

      }
      this.setCartTotalWithoutPM(parseFloat(total.toString()))
      this.setProcessingFee(processingFee)
      total += (paymentFee + processingFee)
      this.setCartTotal(total);
      return {
        total,
        productTotal,
        paymentFee,
        paymentFeeVat: paymentFeeVat,
        paymentFeeVatPerc: this.company.vat_percentage,
        processingFee,
        processingFeeVat: this.calculateVat(processingFee, this.company.vat_percentage),
        processingFeeVatPerc: this.company.vat_percentage,
        discount,
        discountVat: this.calculateVat(discount, this.company.vat_percentage),
        discountVatPerc: this.company.vat_percentage,
        totalProductCount,
        products: this.cart.products.map((p) => {
          return {
            id: p.id,
            amount: p.amount,
            total: p.amount * p.price,
            name: p.name,
            vat: p.vat * p.amount,
            vat_percentage: p.vat_percentage
          }
        })
      }
    },
    ...mapState(['cart', 'event', 'company', 'order', 'campaign'])
  },
  created() {
    this.loadCompany()
  }
}
</script>
<style scoped>
@media only screen and (min-width: 992px) {
  #cart {
    padding: 0 35px 0 10px;
  }
}
.vat {
  font-size: 12px;
}
.small-text{
  font-size:12px
}
.cart-title{
  font-size: 24px;
  font-weight:600;
  margin-bottom:15px;
}
.cart-row {
  padding: 15px 20px !important;
  margin-bottom: 2px !important;
}

.cart-row .cart-product-details {
  font-size: 12px !important;
}
.cart-total-container {
  margin-top: 20px !important;
  margin-bottom: 40px;
}
.cart-totals {
  padding-right: 34px;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 8px !important;
}
@media only screen and (max-width: 992px) {
  .cart-title {
    margin-top: 40px;
  }
  .cart-title, .cart-total-container {
    padding-inline-start: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .cart-title {
    font-size:16px
  }
  .cart-totals{
    padding-right:28px;
    font-size:12px!important
  }
  .cart-row .cart-product-details{
    font-size:10px!important
  }
  .cart-row{
    padding: 10px 17px !important;
  }
}
</style>
