<template>
  <div id="app">
    <shop v-if="layout === 'Shop'"></shop>
    <login v-if="layout === 'Login'"></login>
    <default v-if="layout === 'Default'"></default>
    <BoxOfficeDefault v-if="layout === 'BoxOfficeDefault'"></BoxOfficeDefault>
    <ScannerTerminalDefault v-if="layout === 'ScannerTerminalDefault'"></ScannerTerminalDefault>
    <Static v-if="layout === 'Static'"></Static>
  </div>
</template>

<script>
import Default from '@/layouts/Default'
import Shop from '@/layouts/Shop'
import Login from '@/layouts/Login'
import BoxOfficeDefault from '@/layouts/BoxOfficeDefault'
import ScannerTerminalDefault from '@/layouts/ScannerTerminalDefault'
import Static from "@/layouts/Static.vue";
import {mapState} from 'vuex'

export default {
  components: {
    Shop, Default, Login, BoxOfficeDefault, ScannerTerminalDefault, Static
  },
  name: 'App',
  data() {
    return {
      layouts: {
        Index: 'Shop',
        ViewPointOfSale: 'Shop',
        OrderEnterPersonalDetails: 'Shop',
        OrderAdditionalProducts: 'Shop',
        OrderSelectPaymentMethod: 'Shop',
        OrderSelectTimeslot: 'Shop',
        PayOrder: 'Shop',
        CustomerOrderOverview: 'Shop',
        CustomerOrderPending: 'Shop',
        AssignTickets: 'Shop',
        ClaimTicket: 'Shop',
        Login: 'Login',
        BoxOfficeLogin: 'Login',
        ScannerTerminalLogin: 'Login',
        OrderThankYou: 'Shop',
        OrderStatus: 'Shop',
        NotFound: 'Login',
        TermsConditions: 'Shop',
        TicketChanged: 'Shop',
        UserResetPassword: 'Login',
        Signup: 'Static',
        SignupConfirmation: 'Static',
        TicketLost: 'Static'
      }
    }
  },
  watch: {
    '$route'() {
      this.setLayout()
    }
  },
  computed: {
    ...mapState(['layout'])
  },
  methods: {
    setLayout() {
      let name = this.$route.name
      let layout = this.layouts[name] ? this.layouts[name] : 'Default'
      if(name !== 'BoxOfficeLogin' && this.$route.path.startsWith('/box-office')) {
        this.$store.commit('setLayout', 'BoxOfficeDefault')
      } else if( name !== 'ScannerTerminalLogin' && this.$route.path.startsWith('/scanner-terminal') ){
        this.$store.commit('setLayout', 'ScannerTerminalDefault')
      } else {
        this.$store.commit('setLayout', layout)
      }
    }
  },
  created() {
    if (location.protocol !== 'https:' && location.port !== '8080') {
      location.replace(`https:${location.href.substring(location.protocol.length)}`);
      return
    }
    this.setLayout()
  }
}
</script>
<style>
.font-weight-bold{
  font-weight:600!important
}

.SmallDiv{
  width: 16.6666666667%;
}
.bigDiv{
  width: 83.333333%;
}
@media (max-width:1750px) {
  .SmallDiv{
  width: 20%;
}
.bigDiv{
  width: 80%;
}
}
@media (max-width:1550px) {
  .SmallDiv{
  width: 22%;
}
.bigDiv{
  width: 78%;
}
}
@media (max-width:1450px) {
  .SmallDiv{
  width: 25%;
}
.bigDiv{
  width: 75%;
}
}
@media (max-width:1250px) {
  .SmallDiv{
  width: 30%;
}
.bigDiv{
  width: 70%;
}
}
@media (max-width:1150px) {
  .SmallDiv{
  width: 35%;
}
.bigDiv{
  width: 65%;
}
}
@media (max-width:992px) {
  .SmallDiv{
  width: 100%;
}
.bigDiv{
  width: 100%;
}
.content-main-container{
  margin-top: 0!important;
}
}
.b-popover .popover-header{
  display: none!important;
}
.vs__selected{
  background: #193e58!important;
  color: white!important;
}
.vs__deselect{
  fill:white!important
}
.filterTable .table.b-table > thead > tr > [aria-sort] {
    background-position: right calc(2.4rem / 2) center;
}
.bs-popover-bottom > .arrow::after{
border-bottom-color: #193e58!important;
}
.filterTable table.b-table tbody tr{
  font-size: 13px!important;
}
@media (max-width:1000px) {
  .email-wrapper  
  {
    width: 100%;
    overflow: hidden;
  }
  .email-wrapper > tbody:first-of-type tr:nth-of-type(2) td >table{
    width: 90%;
    margin:auto
  }
  .email-wrapper > tbody:first-of-type tr:nth-of-type(2) td >table table{
    width: 100%;
    margin:auto
  }
  .email-wrapper tbody tr:nth-of-type(2) td table h1{
    font-size: 2rem;
  }
  .email-wrapper tbody tr:nth-of-type(2) td table p{
    font-size:.9rem;
  }
  .email-wrapper tbody tr:nth-of-type(2) td table p table tbody table{
    margin: 0!important;
    width:100%;
  }
  .email-wrapper .button{
    padding: 5px 10px!important;
  }
  .email-wrapper > tbody:first-of-type tr:nth-of-type(2) td >table table:last-of-type td{
    font-size:.8rem;
  }

}
*, h1, table tr td, p{
  font-family: Roboto, sans-serif!important;
}
 table tr td,p{
  color: #808080!important;
}
.scanner-terminal-tickets-table tbody tr:hover {
  background-color: #ffffff !important;
}
</style>
