<template>
<div class="pos-number-input">
    <span @click="subtract" class="btn-subtract">-</span>
    <span class="value">{{ value }}</span>
    <span @click="add" class="btn-plus">+</span>
</div>
</template>

<script>
export default {
  name: "PosNumberInput",
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: null
    },
    value: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 1
    }
  },
  methods: {
    add() {
      if(this.max !== null && (this.value + this.step) > this.max) {
        return;
      }
      let value = this.value + this.step;
      this.$emit('input', value)
      this.$emit('change', value)
    },
    subtract() {
      if(this.min !== null && (this.value - this.step) < this.min) {
        return;
      }
      let value = this.value - this.step;
      this.$emit('input', value)
      this.$emit('change', value)
    }
  },
  mounted () {
    if(this.value === null || this.value === undefined) {
      this.$emit('input', 0)
      this.$emit('change', 0)
    }
  },
  watch: {
    value(val) {
      if(val === null || val === undefined) {
        this.$emit('input', 0)
        this.$emit('change', 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pos-number-input {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  .btn-plus, .btn-subtract {
    width: 27px;
    height: 27px;
    border-radius: 15px;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 27px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    transition: all .2s linear;
  }
  .value {
    text-align: center;
    line-height: 27px;
    font-size: 18px;
    font-weight: bold;
    color: #0A2336;
    width: 27px;
    height: 27px;
    display: inline-block;
  }

  .btn-plus {
    background-color: #2AA937;
  }

  .btn-subtract {
    background-color: #909090;
  }

  input {
    -webkit-appearance: none !important;
    border: 1px solid #ebebeb;
    float: left;
    font-size: 16px;
    height: 40px;
    margin: 0;
    outline: none;
    text-align: center;
    width: calc(100% - 80px);
    overflow: visible;
  }

  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }

  @media only screen and (max-width: 992px) {
    .btn-plus, .btn-subtract {
      width: 22px;
      height: 22px;
      line-height: 22px;
      font-size: 14px;
    }
    
    .value {
      line-height: 22px;
      font-size: 14px;
      width: 22px;
      height: 22px;
      display: inline-block;
    }
  }
}
@media only screen and (max-width: 768px) {
    .pos-number-input{
      gap:10px;
      margin-left:10px
    }
  }
</style>