import { render, staticRenderFns } from "./PosCartPlaceOrder.vue?vue&type=template&id=35acaf2e&scoped=true"
import script from "./PosCartPlaceOrder.vue?vue&type=script&lang=js"
export * from "./PosCartPlaceOrder.vue?vue&type=script&lang=js"
import style0 from "./PosCartPlaceOrder.vue?vue&type=style&index=0&id=35acaf2e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35acaf2e",
  null
  
)

export default component.exports