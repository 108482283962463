<template>
  <b-container class="p-0">
    <router-link v-if="order" :to="{name: 'OrderEnterPersonalDetails', params: {id: order.id, secret: order.secret}, query: backQuery}" class="text-white mb-3 d-block back-btn">
      <b-icon icon="arrow-left"></b-icon> {{ $t('back') }}
    </router-link>
    <b-row v-if="$route.query.payment_retry">
      <b-col>
        <b-alert variant="danger" show>{{ $t('payment_failed') }}</b-alert>
      </b-col>
    </b-row>
    <b-row v-if="order && pos && !saving" class="align-items-center">
      
      <gtm-page-view v-if="order && order.event && order.event.gtm_id" :gtm_id="order.event.gtm_id"></gtm-page-view>

      <b-col v-if="order && cartTotal > 0">
        <div class="content-block cart-container cart-container-title payment-method-title header-padding">
          <b-row>
            <b-col>
              <h1 class="mb-0">{{ $t('pos_payment') }}</h1>
              <label class="mb-0">{{ $t('pos_payment_description') }}</label>
            </b-col>
            <b-col cols="3" class="text-right d-flex align-items-center justify-content-end">
              <order-timer v-if="order" :order="order"></order-timer>
            </b-col>
          </b-row>
        </div>
        <b-row class="m-0 p-0 payments-wrapper mb-4">
          <b-col sm="12" md="6" v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" @click="selectRow(paymentMethod.id)" class="payment-method-row cursor-pointer" :class="'pay'+paymentMethod.id === selectedOption ? 'active-option' : ''">
            <div class="row-item pos-primary-color">
              <b-row class="h-100">
                <b-col cols="1" md="3" class="d-flex justify-content-start justify-content-md-center align-items-center">
                  <span class="pm-img">
                    <b-img :src="require('@/assets/payment-methods/' + paymentMethod.image_active)"></b-img>
                  </span>
                </b-col>
                <b-col class="d-flex align-items-center" cols="11" md="9">
                  <div>
                    <p class="pos-primary-color mb-0 payment-name">{{ paymentMethod.payment_method.name }}</p>
                    <span class="mb-0">
                      <span v-if="paymentMethod.fee_fixed" class="payment-fee"> +{{ (Math.ceil(paymentMethod.fee_fixed * 100) / 100 )| currency }}</span>
                      <span v-if="paymentMethod.fee_percentage" class="payment-fee">
                        {{ ' +' +(paymentMethod.fee_percentage ? ' ' : '') + paymentMethod.fee_percentage + '%' }}
                        ({{ getCartPaymentFee(paymentMethod) | currency }})
                      </span>
                    </span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-else class="content-block cart-container">
        <h3>{{ $t('pos_free_order_btn') }}</h3>
      </b-col>
      <b-col lg="5">
        <div>
          <cart></cart>
        </div>
      </b-col>
      <PosCartPlaceOrder @continue="processOrder()"/>
    </b-row>
    <b-row v-if="saving">
      <b-col class="text-center">
        <h1>{{ $t('payment_loading') }}</h1>
        <b-spinner style="width: 10em; height: 10em;"></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'
import Cart from '@/components/Cart'
import moment from 'moment'
import GtmPageView from '@/components/GtmPageView.vue'
import PosCartPlaceOrder from '@/components/PosCartPlaceOrder.vue';
import OrderTimer from '@/components/OrderTimer.vue';


export default {
  components: {
    OrderTimer,
    PosCartPlaceOrder,
    Cart,
    GtmPageView
  },
  data() {
    return {
      paymentMethodId: null,
      saving: false,
      selectedOption: null
    }
  },
  methods: {
    selectRow(key){
      this.selectedOption = 'pay' +  key
      this.paymentMethodId = key;
      this.selectPaymentMethod();
    },
    selectPaymentMethod() {
      let paymentMethod = this.pos.payment_methods.find((posPaymentMethod) => {
        return posPaymentMethod.id === this.paymentMethodId
      })
      this.$store.commit('setCartPaymentMethod', paymentMethod)
    },
    processOrder() {
      this.saving = true
      let order = Object.assign(this.order, {point_of_sale_payment_method_id: this.paymentMethodId})
      order.customer = null;
      if(order.payment_status === 'paid' || order.payment_status === 'pending') {
        this.$router.push({name: 'ViewPointOfSale', params: {url: this.pos.url}});
        this.$store.commit('errors', [this.$t('order_already_paid')]);
        return;
      }
      api.order().save(order).then((order) => {
        this.$store.commit('setOrder', order)
        api.order().pay(this.order).then((url) => {
          document.location = url
        }).catch(() => {
          this.$store.commit('errors', ['error_select_payment_method'])
          this.saving = false
        })
      }).catch((error) => {
        if(error?.response?.data[0]?.code === 'order_already_paid') {
          this.$router.push({name: 'ViewPointOfSale', params: {url: this.pos.url}});
        }
        else {
          this.$store.commit('errors', ['error_select_payment_method'])
        }
        this.saving = false
      })
    },
    getCartPaymentFee(paymentMethod) {
      let paymentFee = 0;
      let paymentFeeVat = 0;

      if (this.cartTotalWithoutPM) {
        const total = this.cartTotalWithoutPM;
        // Payment Fee fixed is inclusive VAT
        // Calculate the fee that is needed for the percentage
        let feePercentage = 0
        if (paymentMethod.fee_percentage > 0) {
          feePercentage = (this.cartProcessingFee + total + paymentMethod.fee_fixed) * (paymentMethod.fee_percentage / 100)
          paymentFee += feePercentage
        }
        // Add the VAT to the payment fee (only from the percentage)
        if(this.company.vat_percentage && paymentFee){
          paymentFeeVat = parseFloat( this.calculateVatFromEx(feePercentage, this.company.vat_percentage) )
          if(paymentFeeVat){
            paymentFee += paymentFeeVat
          }
        }

        // Rounds to two decimals
        if(paymentFee){
          return paymentFee = paymentMethod.fee_fixed + (Math.round((paymentFee + Number.EPSILON) * 100) / 100)
        }
      }
      return 0
    },
    calculateVatFromEx(price, percentage){
      let vat = price * (percentage / 100)
      return vat
    },
  },
  computed: {
    paymentMethods(){
      return this.pos.payment_methods.filter((pm) => {
        // Only return the active payment methods & show_pos for the POS
        if(!pm.online && !pm.show_pos){
          return false
        }
        // Hide STC Transfer in case the event is within 3 days
        if(pm.payment_method.adapter !== 'App\\PaymentAdapters\\PayNLSTC') {
          return true
        }
        let daysLeft = moment(this.event.starts_at).diff(moment(), 'days')
        let dayOfWeek = moment(this.event.starts_at).format('E')
        let daysNeeded = 3;
        // add 2 more days if the event is in the weekend
        if (dayOfWeek >= 6) {
          daysNeeded += 2
        }
        if(daysLeft > daysNeeded){
          return true
        }
        return false
      }).map((pm) => {
        let img = pm.payment_method.adapter.split('\\').pop().toLowerCase()
        pm.image = img  + '-grey.svg'
        pm.image_active = img + '.svg'
        return pm
      })
    },
    hasTimeslots() {
      if (!this.event) {
        return false
      }
      return this.event.timeslots.length > 0 || (this.event.opening_hours && this.event.opening_hours.length > 0)
    },
    backQuery() {
      if(this.campaign) {
        return {ft_campaign_name: this.campaign.slug, back: ''};
      }
      return {back: ''};
    },
    ...mapState(['cart', 'order', 'pos', 'event', 'campaign', 'cartTotal', 'cartTotalWithoutPM', 'company', 'cartProcessingFee'])
  },
  created() {
    if(document.body.contains(document.getElementById('company-header'))){
      document.getElementById("company-header").scrollIntoView();
    }
    document.title = this.$t('pos_payment_method_title')
    api.order().getBySecret(this.$route.params.id, this.$route.params.secret).then((order) => {
      if(order && order.is_cancelled){
        this.$store.commit('errors', ['order_cancelled'])
        this.$router.push({name: 'ViewPointOfSale', params: {url: order.point_of_sale.url}});
        return
      }
      this.$store.commit('setOrder', order)
    })
    if(this.$store && this.$store.state && this.$store.state.cart && this.$store.state.cart.paymentMethod){
      this.selectedOption = 'pay' + this.$store.state.cart.paymentMethod.id
    }

  }
}
</script>
<style scoped>
  h3{
    font-size:1.5rem;
    font-weight:600;
    margin-bottom:20px
  }
  .payment-method-row {
    border-radius: 6px;
    margin: 0 !important;
    min-height: 65px
  }
  .payment-method-title {
    padding:42px 47px!important;
  }
  @media only screen and (max-width: 992px) {
    .payment-method-row {
      padding: 11px 17px !important;
    }
  }

  .payment-method-row .row-item{
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 100%;
    line-height: 1rem;
  }
  .payment-method-row .payment-fee {
    font-size:10px !important;
    color: #909090;
    margin:0
  }

  .payment-method-title {
    border-radius: 6px !important;
  }

  .pm-img {
    width: 37px;
  }
  .pm-img > img{
    max-width: 37px;
  }

  .free-order-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  input[type="radio"]{
    visibility: hidden;
    height: 0;
    width: 0;
  }

  .payments-wrapper {
    margin-top: 1px;
  }

  @media only screen and (max-width: 768px) {
    .payment-method-title {
      padding:17px!important;
    }
    .pm-img ,.pm-img > img{
      width: 24px;
    }
    .payment-method-row .row-item .row{
      gap:10px;
      flex-wrap:nowrap
    }
    .payment-name{
      font-size:12px
    }
  }
</style>
