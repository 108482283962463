<template>
  <div
    class="event-card"
    :class="[pos.event.image == null || pos.event.image == '' ? 'n-img' : '']"
  >
    <div class="img-container" v-if="pos.event.image">
      <img :src="pos.event.image_url" class="cover-img" :alt="pos.event.name" />   
    </div>

    <div class="info-block">
      <b-row>
        <b-col cols="12" md="6">
          <h3 class="event-lbl">{{ pos.event.name }}</h3>

          <div class="date-block">
            <div class="icon">
          <span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
            >
              <g transform="translate(0.25 0.25)">
                <g transform="translate(7.217 3.63)">
                  <path
                      d="M239.563,124.354l-2.182-1.636v-3.333a.606.606,0,0,0-1.212,0v3.636a.6.6,0,0,0,.242.485l2.424,1.818a.606.606,0,0,0,.727-.97Z"
                      transform="translate(-236.169 -118.779)"
                      :fill="company.layout.secondary_color"
                      :stroke="company.layout.primary_color"
                      stroke-width="0.5"
                  />
                </g>
                <g>
                  <path
                      d="M7.823,0a7.823,7.823,0,1,0,7.823,7.823A7.832,7.832,0,0,0,7.823,0Zm0,14.435a6.611,6.611,0,1,1,6.611-6.611A6.62,6.62,0,0,1,7.823,14.435Z"
                      :fill="company.layout.primary_color"
                      :stroke="company.layout.primary_color"
                      stroke-width="0.5"
                  />
                </g>
              </g>
            </svg>
          </span>
            </div>

            <div class="date-text" v-if="!pos.event.continuous">{{  getDateFormat(pos.event.starts_at) }} - {{  getDateFormat(pos.event.ends_at) }}</div>
          </div>

          <div v-if="pos.event.venue" class="date-block">
            <div class="icon">
            <span>
              <svg width="18" viewBox="0 0 8.4666669 8.4666669" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:svg="http://www.w3.org/2000/svg">
                <defs id="defs2"/>
                <g id="layer1" transform="translate(0,-288.53332)">
                <path d="m 4.2324219,288.79688 c -1.6042437,0 -2.9101556,1.30591 -2.9101563,2.91015 -10e-7,2.82277 2.7460938,4.96875 2.7460938,4.96875 a 0.26460978,0.26460978 0 0 0 0.3300781,0 c 0,0 2.7460996,-2.14598 2.7460937,-4.96875 -3.4e-6,-1.60424 -1.3078657,-2.91015 -2.9121093,-2.91015 z m 0,0.52929 c 1.3182605,0 2.3828097,1.0626 2.3828125,2.38086 4.8e-6,2.30926 -2.0910618,4.13374 -2.3808594,4.38086 -0.2884142,-0.24588 -2.3828134,-2.0707 -2.3828125,-4.38086 5e-7,-1.31826 1.0625988,-2.38086 2.3808594,-2.38086 z" id="path929" style="color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.52916664;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:stroke fill markers;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate"/>
                <path d="m 4.2324219,290.38477 c -0.7274912,0 -1.3222633,0.59477 -1.3222657,1.32226 -4.5e-6,0.7275 0.5947697,1.32422 1.3222657,1.32422 0.727496,0 1.3242233,-0.59672 1.3242187,-1.32422 -2.3e-6,-0.72749 -0.5967275,-1.32226 -1.3242187,-1.32226 z m 0,0.52929 c 0.4415089,0 0.7949204,0.35146 0.7949219,0.79297 2.7e-6,0.44151 -0.35341,0.79492 -0.7949219,0.79492 -0.441512,0 -0.7929715,-0.35341 -0.7929688,-0.79492 1.4e-6,-0.44151 0.3514598,-0.79297 0.7929688,-0.79297 z" id="circle931" style="color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.52916664;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:stroke fill markers;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate"/>
                </g>
              </svg>
            </span>
            </div>
            <div class="date-text">{{ getVenueAddress(pos.event.venue) }}</div>
          </div>
        </b-col>
        <b-col cols="12" md="6" class="pos-btn-div mt-3 mt-md-0 text-right d-flex justify-content-start justify-content-md-end align-items-center">
          <div class="btn-container">
            <a :href="`/p/` + pos.url + ($route.query.ft_campaign_name ? ('/?ft_campaign_name='+$route.query.ft_campaign_name) : '')" class="btn pos-btn-order">{{ $t('pos_open') }}</a>
          </div>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex';
export default {
  props: {
    pos: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      readMoreId: null,
    };
  },

  methods: {
    readMore(id) {
      if (this.readMoreId) {
        this.readMoreId = null;
      } else {
        this.readMoreId = id;
      }
    },
    getDateFormat(date) {
      return moment(date).format('D MMM YYYY HH:mm');
    },
    getVenueAddress(venue) {
      let location = '';
      if(venue.name) {
        location = venue.name;
      }
      if(venue.address) {
        location = location + (location.length ? ' | ' : '') + venue.address;
      }
      if(venue.zip) {
        location = location + (location.length ? ', ' : '') + venue.zip;
      }
      if(venue.city) {
        location = location + (location.length ? ', ' : '') + venue.city;
      }
      if(venue.country_code) {
        location = location + (location.length ? ', ' : '') + venue.country_code;
      }
      return location;
    }
  },

  computed: {
    ...mapState(['company'])
  }
};
</script>

<style lang="scss" scoped>

// color
$white: #fff;
$black: #303030;
$black2: #4e4e4e;
$red: #9b0000;
$primary: #40cd8a;
$dark: $black;

// Body
$body-bg: #fcfdff;

// Typography
$font-family-sans-serif: "Poppins", sans-serif;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1166px,
);


.event-card {
  width: 100%;
  max-width: 1166px;
  margin-left: auto;
  margin-right: auto;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000017;
  border-radius: 6px;
  display: grid;
  padding:0 35px;
  margin-bottom: 1px;
  min-height:147px;
  
  .cover-img {
    object-fit: cover;
    object-position: center;
  }

  .img-container img{
    width:150px;
    height:147px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .info-block {

    .event-lbl {
      font-weight: 600;
      font-size: 24px;
    }

    .date-block {
      display: flex;
      gap: 9px;
      align-items: baseline;
      &:not(:last-of-type){
        margin-bottom:3px
      }
      .date-text {
        font-size: 16px;
        color: #909090;
      }
    }

    .about-block {
      padding-top: 26px;
      width: 100%;

      .text {
        font-size: 15px;
        margin-bottom: 0px;
        line-height: 1.75;
        overflow: hidden;
        max-height: 80px;
        text-overflow: ellipsis;
        transition: max-height 0.35s ease-in-out;

        &.show {
          max-height: 700px !important;
        }
      }

      .read-more {
        padding-top: 5px;

        .btn {
          font-size: 15px;
          font-weight: 500;
          padding: 0;

          &:hover {
            color: lighten($color: $red, $amount: 5%);
          }
        }
      }
    }
  }

  .btn-container {
    .btn {
      box-shadow: 0px 3px 8px #00000029;
      border-radius: 6px;
      font-size: 18px;
      font-weight: 500;
      color: $white;
      padding: 10.5px 28px;
      white-space: nowrap;
    }
    .btn.btn-success{
      background-color:#2AA937
    }
    .btn.btn-success:hover{
      background-color:darken($color:#2AA937, $amount: 10%)
    }
  }

  .content-block {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-end;
    padding:0;
    box-shadow:none;
    margin-top:auto
  }

  .info-block{
    padding: 16px 0;
    display:flex;
    flex-direction:column;
    justify-content: center;
  }

  &:not(.n-img) {
    grid-template-columns: 180px 1fr;
    padding: 0;
    padding-right:35px;
    .info-block {
      .about-block {
        max-width: 506px;
      }
    }
  }

  &:is(.n-img) {
    grid-template-columns: 1fr;
    padding-top: 10px;
    padding-bottom: 10px;

    .info-block {
      .about-block {
        max-width: 870px;
      }
    }
  }
  
}

@media (max-width: 991px) {
  .event-card {
    padding-inline-start: 20px;
    .cover-img {
      width: 100%;
      height: 100%;
    }

    .content-block {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
    .info-block {
      padding-top: 10px;
      .event-lbl {
        font-size: 20px;
        margin-bottom: 4px;
      }

      .date-block {
        .date-text {
          font-size: 12px;
        }
      }

      .about-block {
        padding-top: 10px;

        .text {
          font-size: 12px;
          line-height: 1.5;
          max-height: 62px;
        }

        .read-more {
          padding-top: 0;

          .btn {
            font-size: 12px;
          }
        }
      }
    }

    .btn-container {
      .btn {
        font-size: 12px;
        padding: 6.5px 22px;
      }
    }
  }
}

@media (max-width: 767px) {
  .event-card {
    display: flex;
    flex-direction: column;
    min-height:unset;
    &:not(.n-img) {
      grid-template-columns: minmax(180px, 260px) 1fr;
      gap: 0px;
      padding-right:0
    }
    
    &:is(.n-img) {
      padding:0
    }
    
    .img-container {
      max-width: 100%;
      width: 100%;
      max-height: 100%;
      margin: auto;
      height: 100%;
      padding: 17px 20px 0 20px;
      img{
        border-radius: 6px!important;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
      }

    }

    .info-block {
      padding:17px 20px;

      .event-lbl {
        font-size: 16px;
      }
    }

    .date-block {
      margin-bottom:0!important;
      .icon {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

@media (max-width: 415px) {
  .img-container{
    width:100%!important;
    max-width:100%!important;
    max-height:unset!important;
    img{
      max-width:100% !important;
    }
  }

}

</style>
