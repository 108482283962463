<template>
  <div class="calender-wrapper">
    <div class="calender-month pos-bg-accent-color">{{ month }}</div>
    <div v-if="from && to" class="calender-day-2">
      <span class="d-block pos-primary-color">{{ from }}</span>
      <span class="dash pos-bg-primary-color"></span>
      <span class="d-block pos-primary-color">{{ to }}</span>
    </div>
    <div v-else class="calender-day">
      {{ from }}
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
 export default {
   name: 'CalenderDay',
   props: ['startsAt', 'endsAt'],
   data() {
     return {
       from: null,
       to: null,
       month: null,
     }
   },
   mounted () {
     this.calculate();
   },
   methods: {
     calculate() {
        const start = moment(this.startsAt);
        const end = moment(this.endsAt);
        
        // Calculate the end time for comparison (07:00 on the next day)
        const limit = start.clone().add(1, 'day').startOf('day').hour(7);

        if (start.isSame(end, 'day')) {
          // Same day event
          this.from = start.format('DD');
          this.to = null;
        } else if (end.isBefore(limit)) {
          // Event ends before 07:00 the next day
          this.from = start.format('DD');
          this.to = null;
        } else {
          // Event spans more than one day
          this.from = start.format('DD');
          this.to = end.format('DD');
        }

        this.month = start.format('MMM').replace('.', '').toUpperCase();
      }
   },
   watch: {
     '$store.state.language' () {
       this.calculate();
     }
   }
 }
</script>

<style lang="scss" scoped>
.calender-wrapper {
  width: 74px;
  height: 90px;
  border: 1px solid #CECECE;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;

  @media(max-width:576px){
    width: 50px;
    height: 60px;
  }
  .calender-month {
    height: 26px;
    line-height: 26px;
    width: 100%;
    color: #FFFFFF;
    font-size: 14px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    @media(max-width:576px){
      height: 22px;
      line-height: 23px;
      font-size: 10px;
    }
  }

  .calender-day {
    font-size: 32px;
    color: #0A2336;
    padding-top: 7px;
    @media(max-width:576px){
      font-size: 17px;
    }
  }

  .calender-day-2 span{
    font-size: 20px;
     @media(max-width:576px){
      font-size: 12px;
    }
  }

  .dash {
    width: 18px;
    height: 2px;
    margin: auto;
    display: block;
  }
  
}
</style>