<template>
  <div class="pos-products-list">
    <div v-for="(group, index) in groups" :key="group.name" class="product-group pos-bg-secondary-color" :class="{'group-name-is-default': $store.state.posFirstGroupIsDefault && index === 0}">
      <h2 class="group-title" v-if="group.type != 'default'">{{ group.name }}</h2>
      <b-row v-for="(product, prodIndex) in group.products.filter(p => p.show && p.showDependency)" :key="`product` + product.id" class="product-row pos-primary-color-border" :class="{'product-row-campaign': product.price !== product.discount_price}">
        <template v-if="product.product_type_id !== donation_product_id">
          <b-col lg="10" md="8" sm="7" class="product-desc-col p-0">
            <b-row class="h-100 align-content-between">
              <b-col class="product-details" lg="10" sm="12">
                <div v-if="product.image_url" class="d-none d-lg-block" style="margin-right:30px">
                  <b-img :src="product.image_url" class="border-radius product-image"></b-img>
                </div>
                <div>
                  <h4 v-if="!product.description || !product.description.length || product.description.length < 50" class="font-weight-bold mb-1">
                    {{ product.name }} 
                    <span v-if="product.persons" class="product-persons product-persons-web pos-bg-primary-color ml-1">
                      <svg class="mr-1" fill="#fff" width="11" height="11" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32" xml:space="preserve"><path d="M25.838 31H6.162a3.957 3.957 0 0 1-3.245-1.661 3.956 3.956 0 0 1-.549-3.604l.704-2.113a6.034 6.034 0 0 1 4.966-4.059C10.131 19.307 13.211 19 16 19c2.788 0 5.869.307 7.963.563a6.032 6.032 0 0 1 4.965 4.059l.704 2.113a3.954 3.954 0 0 1-.55 3.604A3.955 3.955 0 0 1 25.838 31zM16 21c-2.688 0-5.681.298-7.718.549a4.02 4.02 0 0 0-3.312 2.706l-.704 2.112c-.206.618-.106 1.274.274 1.802S5.511 29 6.162 29h19.676a1.98 1.98 0 0 0 1.622-.83c.381-.528.48-1.185.275-1.803l-.704-2.112a4.02 4.02 0 0 0-3.312-2.706C21.681 21.298 18.687 21 16 21zM16 18c-4.687 0-8.5-3.813-8.5-8.5S11.313 1 16 1c4.687 0 8.5 3.813 8.5 8.5S20.687 18 16 18zm0-15c-3.584 0-6.5 2.916-6.5 6.5S12.416 16 16 16s6.5-2.916 6.5-6.5S19.584 3 16 3z"/></svg>
                      {{ product.persons }}  
                      {{ $t('persons') }}
                    </span>
                  </h4>
                  <h4 v-else-if="product.description && product.description.length" @click="toggleDescription(product)" v-b-toggle="`product-description-${index}-${prodIndex}`" class="font-weight-bold mb-1">{{ product.name }} 
                    <span v-if="product.persons" class="product-persons product-persons-web pos-bg-primary-color ml-1">
                      <svg class="mr-1" fill="#fff" width="11" height="11" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32" xml:space="preserve"><path d="M25.838 31H6.162a3.957 3.957 0 0 1-3.245-1.661 3.956 3.956 0 0 1-.549-3.604l.704-2.113a6.034 6.034 0 0 1 4.966-4.059C10.131 19.307 13.211 19 16 19c2.788 0 5.869.307 7.963.563a6.032 6.032 0 0 1 4.965 4.059l.704 2.113a3.954 3.954 0 0 1-.55 3.604A3.955 3.955 0 0 1 25.838 31zM16 21c-2.688 0-5.681.298-7.718.549a4.02 4.02 0 0 0-3.312 2.706l-.704 2.112c-.206.618-.106 1.274.274 1.802S5.511 29 6.162 29h19.676a1.98 1.98 0 0 0 1.622-.83c.381-.528.48-1.185.275-1.803l-.704-2.112a4.02 4.02 0 0 0-3.312-2.706C21.681 21.298 18.687 21 16 21zM16 18c-4.687 0-8.5-3.813-8.5-8.5S11.313 1 16 1c4.687 0 8.5 3.813 8.5 8.5S20.687 18 16 18zm0-15c-3.584 0-6.5 2.916-6.5 6.5S12.416 16 16 16s6.5-2.916 6.5-6.5S19.584 3 16 3z"/></svg>
                     {{ product.persons }} 
                     {{ $t('persons') }}
                    </span> 
                    <b-icon class="chevron-down ml-1" :icon="openedProducts.includes(product.id) ? 'chevron-down' : 'chevron-up'"></b-icon>
                  </h4>
                  <span v-if="product.persons" class="product-persons product-persons-mobile pos-bg-primary-color">
                    <svg class="mr-1" fill="#fff" width="11" height="11" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32" xml:space="preserve"><path d="M25.838 31H6.162a3.957 3.957 0 0 1-3.245-1.661 3.956 3.956 0 0 1-.549-3.604l.704-2.113a6.034 6.034 0 0 1 4.966-4.059C10.131 19.307 13.211 19 16 19c2.788 0 5.869.307 7.963.563a6.032 6.032 0 0 1 4.965 4.059l.704 2.113a3.954 3.954 0 0 1-.55 3.604A3.955 3.955 0 0 1 25.838 31zM16 21c-2.688 0-5.681.298-7.718.549a4.02 4.02 0 0 0-3.312 2.706l-.704 2.112c-.206.618-.106 1.274.274 1.802S5.511 29 6.162 29h19.676a1.98 1.98 0 0 0 1.622-.83c.381-.528.48-1.185.275-1.803l-.704-2.112a4.02 4.02 0 0 0-3.312-2.706C21.681 21.298 18.687 21 16 21zM16 18c-4.687 0-8.5-3.813-8.5-8.5S11.313 1 16 1c4.687 0 8.5 3.813 8.5 8.5S20.687 18 16 18zm0-15c-3.584 0-6.5 2.916-6.5 6.5S12.416 16 16 16s6.5-2.916 6.5-6.5S19.584 3 16 3z"/></svg>
                    {{ product.persons }} 
                    {{ $t('persons') }}
                  </span>
                  <p v-if="product.description && product.description.length < 50" class="pos-description-color mb-0" style="white-space: pre;white-space: normal;font-size: 12px;" >{{ product.description }}</p>
                  <b-collapse visible v-else-if="product.description && product.description.length" :id="`product-description-${index}-${prodIndex}`" role="tabpanel">
                    <p class="pos-description-color mb-0" style="white-space: pre-wrap; font-size: 12px;">
                      {{ product.isExpanded ? product.description : product.description.substring(0, 100) + (product.description.length > 100 ? '...' : '') }}
                    </p>

                    <button class="read-desc-btn btn btn-link p-0 pos-primary-color" v-if="product.description.length > 100" @click="toggleExpandDescription(group,product)">
                      {{ product.isExpanded ? $t('read_less') : $t('read_more') }}
                    </button>
                  </b-collapse>
                  <p v-if="product.sale_ends_at" class="pos-description-color mb-0 mt-1 d-flex align-items-center" style="font-style:italic;white-space: pre;white-space: normal;font-size: 12px;" ><b-icon class="mr-2" icon="clock" color="#0A2336" width="15" height="15"></b-icon> {{ getSaleText(product) }}</p>
                </div>
              </b-col>
              <b-col sm="12" lg="2" class="pricing-col">
                <div v-if="!product.out_of_stock">
                  <p v-if="product.price === product.discount_price" class="price pos-primary-color font-weight-bold">{{ product.price | currency }}</p>
                  <p v-else class="price">
                    <span class="d-block old-price">{{ product.price | currency }}</span>
                    {{ product.discount_price | currency }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="2" md="4" sm="5" class="px-0 d-flex justify-content-start justify-content-sm-end align-items-start align-items-lg-center">
            <b-row class="w-100">
              <!-- just for small screen -->
              <b-col v-if="product.image_url" cols="12" class="d-flex justify-content-end mb-2 d-lg-none">
                <b-img fluid :src="product.image_url" class="border-radius product-image" width="100"></b-img>
              </b-col>
              <b-col v-if="!product.is_disabled" class="d-flex justify-content-start justify-content-sm-end align-items-center">
                <PosNumberInput
                    v-if="!product.out_of_stock"
                    @change="updateCart()"
                    :min="Math.min(...product.options)"
                    :max="Math.max(...product.options)"
                    :step="product.sale_sets !== null && product.sale_sets !== 0 ? product.sale_sets : 1"
                    v-model="amounts[product.id]"/>
                <div v-else class="text-danger text-center font-weight-bold solid-out">{{ $t('pos_product_sold_out') }}</div>
              </b-col>
              <b-col class="text-right" v-else>
                <div class="text-danger text-right font-weight-bold">{{ $t('pos_product_expired') }}</div>
              </b-col>
            </b-row>
          </b-col>
        </template>
        <template v-else >
          <div class="donation px-0 py-4">
            <b-col lg="3" class="product-details flex-vertical">
              <h4 v-if="!product.description || !product.description.length || product.description.length < 50" class="font-weight-bold">{{ product.name }} <span v-if="product.persons" class="product-persons product-persons-web pos-bg-primary-color ml-3"><b-icon icon="person-fill"></b-icon> {{ product.persons }} {{ $t('persons') }}</span></h4>
              <h4 v-else-if="product.description && product.description.length" v-b-toggle="`product-description-${index}-${prodIndex}`" class="font-weight-bold">{{ product.name }} <span v-if="product.persons" class="product-persons product-persons-web pos-bg-primary-color ml-3"><b-icon icon="person-fill"></b-icon> {{ product.persons }} {{ $t('persons') }}</span> <b-icon class="chevron-down" icon="chevron-down"></b-icon></h4>
              <span v-if="product.persons" class="product-persons product-persons-mobile pos-bg-primary-color"><b-icon icon="person-fill"></b-icon> {{ product.persons }} {{ $t('persons') }}</span>
              <p v-if="product.description && product.description.length < 50" class="pos-description-color" style="white-space: pre;white-space: normal;font-size: 12px;" >{{ product.description }}</p>
              <b-collapse visible v-else-if="product.description && product.description.length" :id="`product-description-${index}-${prodIndex}`" role="tabpanel">
                <p class="pos-description-color" style="white-space: pre;white-space: normal;font-size: 12px;" >{{ product.description }}</p>
              </b-collapse>
            </b-col>
            <b-col lg="6" md="6" class="flex-horizontal donation-buttons text-right">
              <b-button pill variant="primary" :disabled="amounts[product.id] === 5" @click="amounts[product.id] = 5;updateCart()" class="ml-2" >{{5 | currency }}</b-button>
              <b-button pill variant="primary" :disabled="amounts[product.id] === 10" @click="amounts[product.id] = 10;updateCart()" class="ml-2" >{{10 | currency }}</b-button>
              <b-button pill variant="primary" :disabled="amounts[product.id] === 15" @click="amounts[product.id] = 15;updateCart()" class="ml-2" >{{15 | currency }}</b-button>
            </b-col>
            <b-col lg="3" md="3" class="flex-horizontal">
              <PosNumberInput
                  @change="updateCart()"
                  :min="0"
                  v-model="amounts[product.id]"/>
            </b-col>
          </div>
        </template>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import moment from "moment";
import {applyCampaignHideRule, applyCampaignRule, deepClone} from "@/functions";
import PosNumberInput from './PosNumberInput.vue';
export default {
  components: {PosNumberInput},
  props: ['cross_sell'],
  data() {
    return {
      amounts: {},
      refreshGroups: 0,
      groups:[],
      openedProducts: [],
    }
  },
  watch: {
    cart(){
      console.log('Cart changed')
      this.syncCart()
    },
    groups(groups) {
      if(groups.length) {
        this.setPosFirstGroupIsDefault(groups[0].name === 'event_default_group')
      }
    }
  },
  methods: {
    toggleExpandDescription(group,product) {
      const groupToUpdate = this.groups.find(g => g.name === group.name);
      if (groupToUpdate) {
        // Find the product in the group's products array
        const productToUpdate = groupToUpdate.products.find(p => p.id === product.id);
        if (productToUpdate) {
          // Toggle the isExpanded property
          productToUpdate.isExpanded = !productToUpdate.isExpanded;
          
          // Force Vue to re-render by creating a new array
          this.groups = [...this.groups];
        }
      }
    },
    updateGroups() {
      // Flatten the data from the pos response
      console.log(this.refreshGroups); // this for force update groups when product selected
      this.groups= this.pos.pos_product_groups.filter(posProductGroup => {
        return posProductGroup.product_group
      }).map((posProductGroup) => {
        return posProductGroup.product_group
      }).map(productGroup => {
        // Filter out the additional products
        productGroup.products = productGroup.products.filter((product) => {
          if (this.cross_sell) {
            return product.is_cross_sell
          }
          return !product.is_cross_sell
        }).map((product) => {
          // Initialize isExpanded to false for each product
          product.isExpanded = false;
          return product;
        });
        return productGroup
      }).map((productGroup) => {
        //filter out non enabled pos
        let exists = productGroup.products.filter((product)=>{
          let x = this.pos.products.filter((posProduct)=> {
            return product.id === posProduct.product_id && !posProduct.product.is_disabled
          })
          return x.length > 0
        }).map(this.setProductStock);
        const expired = productGroup.products.filter((product)=>{
          let x = this.pos.products.filter((posProduct)=> {
            return product.id === posProduct.product_id && posProduct.product.is_disabled && (posProduct.product.sale_ends_at !== null || posProduct.product.out_of_stock)
          })
          return x.length > 0
        }).map(this.setProductStock);
        // Set the stock details for the grouped products
        productGroup.products = exists.sort((a,b) => a.sort - b.sort).concat(expired)
        return productGroup
      })
      .filter((productGroup) => {
        // Filter out empty categories
        return productGroup.products.length > 0
      })

      const products = JSON.parse(JSON.stringify(this.cart.products));
      this.cart.products.forEach(product => {
        const exists = this.groups.find(group => {
          return group.products.find(p => p.id === product.id)
        })
        if(!exists) {
          products.splice(products.findIndex(p => p.id === product.id), 1);
          this.amounts[product.id] = 0
        }
      })
      this.setCartProducts(products)  
      if( !products || products.length === 0) {
        this.$store.commit('clearCart')
      }
    },
    setProductStock(product) {
      let showDependency = true;
      if(product.parents.length) {
        showDependency = false;
        product.parents.forEach(pp => {
          if(this.amounts[pp.parent_id] && this.amounts[pp.parent_id] > 0) {
            showDependency = true;
          }
        })
      }

      if(!showDependency) {
        this.amounts[product.id] = 0;
      }

      product = Object.assign(product, {
        discount_price: applyCampaignRule(this.campaign, product.name, deepClone(product).price),
        show: !applyCampaignHideRule(this.campaign, product.name, deepClone(product).price),
        showDependency
      })

      this.pos.pos_product_groups.filter(posProductGroup => {
        return posProductGroup.product_group
      }).forEach(posProductGroup => {
        posProductGroup.product_group.products.forEach(ppp => {
          ppp.additions.forEach(ad => {
            if(ad.child_id === product.id) {
              product.show = false;
            }
          })
        })
      })

      if(product.additions.length) {
        product.additions.forEach(pp => {
          if(this.amounts[pp.parent_id] !== undefined && this.amounts[pp.parent_id] !== null) {
            this.amounts[pp.child_id] = this.amounts[pp.parent_id];
          }
        })
      }

      let posProduct = this.pos.products.find(p => {
        return p.product_id === product.id
      })

      if (!posProduct || posProduct.out_of_stock) {
        console.error('Unable to get stock for product', product.id)
        product.out_of_stock = true
        return product
      }

      if (posProduct) {
        product.current_stock = posProduct.current_stock
        product.sort = posProduct.sort

        let max = 0
        if (product.max_buy && product.max_buy > 0) {
          max = product.max_buy
        }

        if (product.current_stock < max) {
          max = product.current_stock
        }

        if (max === 0) {
          product.out_of_stock = true
          return product
        }

        product.options = []
        if(product.min_buy > 0){
          product.options.push(0)
        }
        let limit = product.current_stock < product.max_buy ? product.current_stock : product.max_buy;
        let step = 1;
        if(product.sale_sets) {
          step = product.sale_sets;
        }

        if(product.limit_max_amount) {
          let maxAmount = 0;
          this.pos.products.forEach(p => {
            const pp =this.amounts[ p.product.id];
            if (p.product.id !== product.id && pp > maxAmount && !p.product.limit_max_amount) {
              maxAmount = pp;
            }
          })
          limit = maxAmount;

          if(this.amounts[product.id] !== undefined || !this.amounts[product.id]) {
            if(this.amounts[product.id] > limit) {
              this.amounts[product.id] = limit;
            }
          }
        }

        for (let i = product.min_buy; i <= limit; i = i + step) {
          product.options.push(i)
        }
      }
      let canSell = true;

      if(product.sale_starts_at) {
        canSell = moment().isAfter(moment(product.sale_starts_at));
      }

      if(product.sale_ends_at) {
        canSell = canSell && moment().isBefore(moment(product.sale_ends_at));
      }
      product.is_disabled = false;
      if(!canSell && product.sale_show_after_expire) {
        if( moment().isAfter(moment(product.sale_ends_at)) ) {
          product.is_disabled = true;
          product.amount = 0;
        }
      }
      return product
    },
    getProduct(id) {
      let posProduct = this.pos.products.find(posProduct => {
        return posProduct.product_id === parseInt(id)
      })
      if (!posProduct) {
        console.error('Unable to find pos product')
        return null
      }

      return posProduct.product
    },
    updateCart() {
      this.refreshGroups++;
      this.$forceUpdate();
      setTimeout(() => {
        let products = Object.keys(this.amounts).filter(id => {
          // filter out the products that have 0 selected
          return this.amounts[id] > 0
        }).map(id => {
          let product = this.getProduct(id)
          if (!product) {
            console.error('Unable to find product from cart in POS')
            return {amount: 0}
          }
          if (product.product_type_id === this.donation_product_id){
            product.price = this.amounts[id]
            product.amount = 1
          }else{
            product.amount = this.amounts[id]
          }
          if(product.min_buy > this.amounts[id]) {
            setTimeout(() => {
              this.amounts[id] = product.min_buy;
              this.$forceUpdate();
            }, 500)
            product.amount = product.min_buy;
          }
          return product
        })
        this.$store.commit('setCartProducts', products)
        if( !products || products.length === 0) {
          this.$store.commit('clearCart')
        }
        this.$forceUpdate()
      }, 200)
    },
    syncCart() {
      if(this.cart.products.length === 0) {
        this.amounts = {}
        return
      }
      this.cart.products.forEach(product => {
        if (product.product_type_id === this.donation_product_id){
          this.amounts[product.id] = product.price
        }else{
          this.amounts[product.id] = product.amount
        }
      })
    },
    getSaleText(product) {
      if(!product.sale_ends_at) {
        return '';
      }

      const ends = moment(product.sale_ends_at);

      if(!product.sale_starts_at &&  moment().isBefore(ends)) {
        const duration = moment.duration(ends.diff(moment()));
        if(duration.months()) {
          if(duration.days()) {
            if(duration.hours()) {
              return this.$t('pos_sale_ends_in_months_days_and_hours', {
                months: duration.months(),
                days: duration.days(),
                hours: duration.hours()
              });
            }
            else {
              return this.$t('pos_sale_ends_in_months_days', {
                months: duration.months(),
                days: duration.days()
              });
            }
          }
          else {
            return this.$('pos_sale_ends_in_months', {months: duration.months()})
          }
        }
        if(duration.days() === 0 && duration.hours() === 0) {
          if(duration.minutes()) {
            return this.$t('pos_sale_ends_in_minutes', {minutes: duration.minutes()});
          }
        }
        if(duration.days() === 0) {
          return this.$t('pos_sale_ends_in_hours', {hours: duration.hours()});
        }
        return this.$t('pos_sale_ends_in_days_and_hours', {days: duration.days(), hours: duration.hours()});
      }

      const starts = moment(product.sale_starts_at);

      if(moment().isBefore(starts)) {
        return this.$t('product_sale_starts_at') + ' ' + starts.format('DD-MM-YYYY HH:mm');
      }

      if(moment().isAfter(starts) && moment().isBefore(ends)) {
        const duration = moment.duration(ends.diff(moment()));
        if(duration.months()) {
          if(duration.days()) {
            if(duration.hours()) {
              return this.$t('pos_sale_ends_in_months_days_and_hours', {
                months: duration.months(),
                days: duration.days(),
                hours: duration.hours()
              });
            }
            else {
              return this.$t('pos_sale_ends_in_months_days', {
                months: duration.months(),
                days: duration.days()
              });
            }
          }
          else {
            return this.$t('pos_sale_ends_in_months', {months: duration.months()})
          }
        }
        if(duration.days() === 0 && duration.hours() === 0) {
          if(duration.minutes()) {
            return this.$t('pos_sale_ends_in_minutes', {minutes: duration.minutes()});
          }
        }
        if(duration.days() === 0) {
          return this.$t('pos_sale_ends_in_hours', {hours: duration.hours()});
        }
        return this.$t('pos_sale_ends_in_days_and_hours', {days: duration.days(), hours: duration.hours()});
      }

      if(moment().isAfter(ends)) {
        return this.$t('pos_sale_ended_at') + ' ' + ends.format('DD-MM-YYYY HH:mm');
      }
    },
    toggleDescription(product) {
      if(this.openedProducts.includes(product.id)) {
        this.openedProducts.splice(this.openedProducts.indexOf(product.id), 1);
      }
      else {
        this.openedProducts.push(product.id);
      }
    },
    ...mapMutations(['setPosFirstGroupIsDefault', 'setCartProducts'])
  },
  computed: {
    ...mapState(['pos', 'cart','donation_product_id', 'campaign'])
  },
  created() {
    this.syncCart()
    this.updateGroups();
    if(this.campaign && this.campaign.highlight_discount && this.campaign.highlight_style) {
      let css = '';
      if(this.campaign.highlight_style.border_color) {
        css += 'box-shadow: 2px 2px 10px -9px ' + this.campaign.highlight_style.border_color + ';';
      }
      if(this.campaign.highlight_style.background_color) {
       css += 'background: linear-gradient(217deg, ' + this.campaign.highlight_style.background_color + ', #FFF);';
      }
      css += 'margin: 0;'
      const style = '.pos-products-list .product-row-campaign {' + css + '}'
      document.querySelector('head').innerHTML += '<style id="product-row-campaign">' + style + '</style>'
    }
  },
}
</script>
<style scoped>
.pos-products-list .product-row-campaign{
  padding: 25px 47px;
}
.pos-products-list .product-row-campaign:last-of-type{
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.content-block.cart-container .product-details p{
  font-size:0.8rem
}
.product-row {
  padding: 25px 47px;
  width: 100%;
  margin: 0;
}
.wrapText{
  white-space: normal!important;
}
.price {
  font-size: 16px;
  margin: 0;
}
.read-desc-btn{
  color:#0A2336;
  font-style: italic;
  font-size:12px;
  box-shadow:none
}
.group-title {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 25px 47px 25px 47px;
  margin:0
}

.product-details {
  display: flex;
  align-items: center;
}
.product-details h4 {
  font-size: 16px;
  margin-bottom:2px;
  display:flex;
  align-items:center;
  gap:.6rem
}
.product-group{
  margin-top:35px
}
.product-details p, .content-block.cart-container .product-details p{
   white-space: normal!important;
  }
.donation{
  border: 1px solid lightgrey;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  display:flex;
  margin:0 2%;
}

.donation-buttons{
  justify-content: end;
  align-items: center;
}

.flex-vertical{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex-horizontal{
  display:flex;
  flex-direction: row;
  align-items: center;
}
.pricing-col div.row div.alert{
  text-align:center
}

.pricing-col {
  display: flex;
  align-items: center;
  justify-content: end;
}
.product-persons {
  padding: 4px 7px;
  color: #FFFFFF !important;
  font-size: 10px !important;
  font-weight: normal !important;
  border-radius: 6px;
  text-transform: lowercase;
}
.product-persons-mobile {
  display: none;
}
.product-image {
  width: 84px;
}
@media screen and (max-width: 994px) {
  .product-row{
    padding: 13px 17px;
  }
  .product-row p.price{
    margin-bottom:0;
    margin-top:1rem;
  }
  .price-select{
    width:120px
  }
  .pricing-col {
    justify-content: start;
  }
  .product-persons-web {
    display: none;
  }
  .product-persons-mobile {
    display: inline-block;
  }
  .group-title {
    padding: 17px 17px 13px 17px;
  }
  .product-group:not(:first-of-type){
    margin-top:15px
  }
}

@media screen and (max-width: 768px) {
  .donation{
    flex-direction: column;
  }
  .donation-buttons{
    margin-bottom:15px
  }
  .pricing-col {
    text-align: left !important;
  }
  .product-details h4 {
    font-size: 12px;
    margin-bottom:2px;
  }
  .product-row p.price,.solid-out{
    font-size:12px
  }
  .product-row p.price{
    margin-top:.5rem;
  }
  .product-details p{
    font-size:10px!important
  }
  .product-details p svg{
    width:10px!important;
    height:10px!important;
  }
  .product-image{
    width:79px!important;
  }
  .pos-products-list .product-row-campaign{
    padding: 13px 17px;
  }
}

@media screen and (max-width: 480px) {
  h2.group-title{
    font-size:13pt
  }
}

@media screen and (max-width: 360px) {
  .product-row div.product-desc-col, .product-row div.pricing-col, .price-select{
    width:100%;
    max-width:100%
  }
  .product-row div.pricing-col div.row{
    flex-direction: row-reverse;
  }
  .product-details p{
    margin-bottom:10px
  }
}
.old-price {
    text-decoration: line-through;
    padding: 0;
    margin: 0;
    line-height: initial;
}

.product-row {
  border-top: 1px solid;
  padding-top: 20px;
}
.product-row:first-child {
  border-top: 0 !important;
}

.product-group {
  border-radius: 6px;
}

.group-name-is-default {
  margin-top: 0 !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.justify-content-sm-end {
  justify-content: flex-end !important;
}

.chevron-down {
  font-size: 12px !important;
}

</style>
