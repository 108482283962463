<template>
  <div class="input-wrapper" :class="{'has-error': localState === false, 'has-icon': !!$slots.icon}">
    <input
        :type="type ? type : 'text'"
        :value="localValue"
        class="form-control"
        :ref="`input-${inputId}`"
        @input="onInput"
        @change="onChange"
        :id="inputId"
        :placeholder="label"
        :disabled="disabled">
    <label class="mt-icon" v-if="$slots.icon">
      <slot name="icon"></slot>
    </label>
  </div>
</template>

<script>
import { randomStr } from '@/functions';

export default {
  name: "MaterialInput",
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    state: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputId: randomStr(),
      localValue: this.value,
      localState: this.state
    }
  },
  methods: {
    onInput(event) {
      const newValue = event.target.value;
      this.localValue = newValue;
      this.localState = this.calculateState(newValue); // Update local state based on new value
      this.$emit('input', newValue); // Emit the new value to parent
    },
    onChange(event) {
      this.$emit('change', event.target.value); // Emit the change event to parent
    },
    calculateState(value) {
      // Replace this with your logic to determine the state based on the value
      return value.length > 0;
    }
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    state(newState) {
      this.localState = newState;
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  position: relative;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  @media(max-width : 768px){
    font-size: 12px;
  }
  &.has-error {
    .form-control {
      border-color: #E80C0C !important;
    }
    .form-control::placeholder {
      color: #E80C0C !important;
    }
    .mt-icon svg{
      fill:#E80C0C !important
    }
    .mt-icon svg path{
      stroke:#E80C0C !important
    }
  }

  .form-control {
    border-radius: 6px !important;
    box-shadow: none;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    padding: 1rem;
    height:64px;
    &:focus {
      outline: none;
    }
    &::placeholder{
      font-weight:400!important;
      color:#909090!important
    }
     @media(max-width : 768px){
      font-size: 12px;
      height:50px;
    }
  }

  &.has-icon {
    .form-control {
      padding: 1rem 1rem 1rem 4rem;
      @media(max-width : 768px){
        padding: 1rem 1rem 1rem 3rem;
      }
    }
  }

  .mt-icon {
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
    @media(max-width : 768px){
      left: 1rem;
    }
    svg {
      font-size: 1.5rem;
      @media(max-width : 768px){
        font-size: 1rem;
      }
    }
  }
}
</style>
