<template>
  <div class="pos-cart-place-order">
    <div class="get-code-input-mobile pos-bg-accent-color">
      <div @click="$event.preventDefault(); showDiscountCodeInput = true" v-if="!showDiscountCodeInput" class="get-code-input d-block">
        <div class="d-inline-block cursor-pointer get-code-input">
          <svg class="get-code-input" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 384 512"><path class="get-code-input" d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128A64 64 0 1 0 0 128a64 64 0 1 0 128 0zM384 384a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/></svg>
        </div>
      </div>
      <div v-if="showDiscountCodeInput">
        <div class="d-flex discount-input get-code-input">
          <div v-if="!cart.discountCode">
            <b-button class="input-button-percentage get-code-input">
              <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 384 512"><path d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128A64 64 0 1 0 0 128a64 64 0 1 0 128 0zM384 384a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/></svg>
            </b-button>
            <b-form-input placeholder="Discountcode" @keyup.enter="claimDiscountcode()" v-model="discountCode" class="discount-form-input get-code-input"></b-form-input>
            <b-button @click="claimDiscountcode()" class="input-button get-code-input">+</b-button>
          </div>
          <div v-if="cart.discountCode" class="d-inline-block ml-3 get-code-input">
              <span class="cart-discountcode get-code-input">
                <span class="pos-primary-color get-code-input">{{ cart.discountCode.code }} ({{ calculatedCart.discount | currency }})</span>
                <span @click="setCartDiscountCode(null); discountCode = null" class="ml-3 cursor-pointer pos-primary-color get-code-input">X</span>
              </span>
          </div>
          <button class="arrow-down-icon"><b-icon  icon="chevron-down"/></button>
        </div>
      </div>
    </div>
    <div class="w-100 pos-bg-accent-color rounded-small-screen">
      <div class="container border-radius">
        <b-row class="align-items-center">
          <b-col cols="12" lg="5" class="d-flex justify-content-between justify-content-lg-start align-items-center get-code-input-web">
            <div @click="$event.preventDefault(); showDiscountCodeInput = !showDiscountCodeInput" class="d-inline-block cursor-pointer get-code-input">
              <svg class="get-code-input" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 384 512"><path d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128A64 64 0 1 0 0 128a64 64 0 1 0 128 0zM384 384a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/></svg>
              <span class="ml-3 get-code-input pos-primary-color">{{ $t('pos_place_order_got_a_code') }}</span>
            </div>

            <div v-if="showDiscountCodeInput && !cart.discountCode" class="d-flex discount-input ml-3 get-code-input">
              <b-button class="input-button-percentage get-code-input">
                <svg xmlns="http://www.w3.org/2000/svg" height="11" width="11" viewBox="0 0 384 512"><path d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128A64 64 0 1 0 0 128a64 64 0 1 0 128 0zM384 384a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/></svg>
              </b-button>
              <b-form-input placeholder="Discountcode" @keyup.enter="claimDiscountcode()" v-model="discountCode" class="get-code-input"></b-form-input>
              <b-button @click="claimDiscountcode()" class="input-button get-code-input">+</b-button>
            </div>
            <div v-if="cart.discountCode" class="d-inline-block ml-3">
              <span class="cart-discountcode">
                <span class="pos-primary-color">{{ cart.discountCode.code }} ({{ calculatedCart.discount | currency }})</span>
                <span @click="setCartDiscountCode(null); discountCode = null" class="ml-3 cursor-pointer pos-primary-color">X</span>
              </span>
            </div>
          </b-col>
          <b-col lg="4" md="4" sm="6" class="px-md-0">
            <span class="d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="21" width="21" class="cart-shop-icon">
                <polygon fill="#000" points="160 96.039 160 128.039 464 128.039 464 191.384 428.5 304.039 149.932 304.039 109.932 16 16 16 16 48 82.068 48 122.068 336.039 451.968 336.039 496 196.306 496 96.039 160 96.039" class="ci-primary"/>
                <path fill="#000" d="M176.984,368.344a64.073,64.073,0,0,0-64,64h0a64,64,0,0,0,128,0h0A64.072,64.072,0,0,0,176.984,368.344Zm0,96a32,32,0,1,1,32-32A32.038,32.038,0,0,1,176.984,464.344Z" class="ci-primary"/>
                <path fill="#000" d="M400.984,368.344a64.073,64.073,0,0,0-64,64h0a64,64,0,0,0,128,0h0A64.072,64.072,0,0,0,400.984,368.344Zm0,96a32,32,0,1,1,32-32A32.038,32.038,0,0,1,400.984,464.344Z" class="ci-primary"/>
              </svg>
              <span class="ml-2 pos-primary-color">{{ $t('pos_place_order_total', {total: parseCurrency(calculatedCart.processingFee + calculatedCart.paymentFee)}) }}</span>
            </span>
          </b-col>
          <b-col lg="3" md="8" sm="6" class="d-flex align-items-center justify-content-between pl-1">
            <div class="total-price mr-1">
              <span class="pos-primary-color">{{ calculatedCart.total.toFixed(2) | currency }}</span>
            </div>
            <div style="padding-left:5px">
              <b-button v-if="this.$store.state.order" @click="$emit('continue')" class="btn pos-btn-order" :variant="disabled ? 'secondary' : 'success'" :disabled="disabled">{{ $t('cart_order') }}</b-button>
              <b-button v-else @click="$emit('placeOrder')" class="btn pos-btn-order" :variant="disabled ? 'secondary' : 'success'" :disabled="disabled">{{ $t('order_continue') }}</b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import api from "@/api";
export default {
  name: "PosCartPlaceOrder",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      discountCode: null,
      discountCodeError: null,
      showDiscountCodeInput: false,
      timeslot: null,
      eventListener: null,
    }
  },
  methods: {
    calculateVatFromEx(price, percentage){
      let vat = price * (percentage / 100)
      return vat
    },
    calculateVat(price, percentage) {
      let excl = price / (1 + percentage / 100)
      let vat = price - excl
      return vat
    },
    claimDiscountcode() {
      this.discountCodeError = null
      if(!this.discountCode){
        this.discountCodeError = false
        return
      }
      if(this.$route.params.id) {
        api.discountcode().getByOrder(this.$route.params.id, this.$route.params.secret, this.discountCode).then(discountCode => {
          this.setCartDiscountCode(discountCode);
          this.$forceUpdate();
        })
      }
      else {
        api.discountcode().getByPOS(this.pos.id, this.discountCode).then(discountCode => {
          this.setCartDiscountCode(discountCode)
          this.$forceUpdate();
        })
      }
    },
    parseCurrency(value) {
      return parseFloat(value).toLocaleString('nl-NL', {minimumFractionDigits: 2})
    },
    ...mapActions(['loadCompany']),
    ...mapMutations(['setCartTotal', 'setCartDiscountCode', 'setCartTotalWithoutPM'])
  },
  computed: {
    calculatedCart() {

      if (!this.event) {
        console.log('Event not in state!')
        return null
      }
      if (!this.company) {
        console.warn('Company not in state, maybe still loading!')
        return null
      }

      if (!this.cart) {
        console.warn('Cart not in state!')
        return null
      }

      let productTotal = 0
      let paymentFee = 0
      let processingFee = 0
      let totalProductCount = 0
      let campaignDiscount = 0;

      this.cart.products.forEach(p => {
        productTotal += p.amount * p.price
        totalProductCount += p.amount
      })

      this.cart.products.forEach( product => {
        if(product.service_fee){
          processingFee += (product.service_fee * product.amount)
        }
      })

      this.cart.products.forEach( product => {
        campaignDiscount += ((product.price - product.discount_price) * product.amount)
      })
      this.$store.commit('setCartCampaignDiscount', campaignDiscount)

      // let total = productTotal + paymentFee + processingFee
      let total = productTotal

      let discount = 0
      if(this.cart.discountCode) {
        // if type is per order
        if (this.cart.discountCode.apply_type === 'order') {
          let productsTotal = 0;
          this.cart.products.forEach(p => {
            if (this.cart.discountCode.products && this.cart.discountCode.products.length) {
              if (this.cart.discountCode.products.includes(p.id)) {
                productsTotal += p.amount * p.discount_price
              }
            }
            else {
              productsTotal += p.amount * p.discount_price
            }
          })
          if (this.cart.discountCode.type === 'percentage') {
            discount = 0 - Math.round(((productsTotal * this.cart.discountCode.amount / 100) + Number.EPSILON) * 100) / 100
          }
          else if (this.cart.discountCode.type === 'value') {
            discount = 0 - this.cart.discountCode.amount
          }
        } else { // if type is per ticket
          for (const p of this.cart.products) {
            if (this.cart.discountCode.products && this.cart.discountCode.products.length) {
              if(!this.cart.discountCode.products.includes(p.id)) {
                continue;
              }
            }
            if (this.cart.discountCode.type === 'percentage') {
              discount -= ((Math.round(((p.discount_price * this.cart.discountCode.amount / 100) + Number.EPSILON) * 100) / 100) * p.amount)
            }
            else if (this.cart.discountCode.type === 'value') {
              discount -= (this.cart.discountCode.amount * p.amount)
            }
          }
        }
      }

      if(campaignDiscount) {
        total -= campaignDiscount;
      }

      total += discount

      if (total < 0) {
        total = 0
      }
      
      let paymentFeeVat = 0

      /**
       * Payment method calculation:
       * Payment fee fixed is already inclusive VAT
       * Payment fee percentage is without VAT
       *
       * Calculation:
       * 1) Add the payment fee fixed
       * 2) Calculate fee percentage = (total products + total ticket fee + fixed fee) * percentage
       * 3) Add VAT for the company to the total
       * 4) Round the total payment fee
       */
      if (this.cart.paymentMethod) {

        // Payment Fee fixed is inclusive VAT
        paymentFee = this.cart.paymentMethod.fee_fixed

        // Calculate the fee that is needed for the percentage
        let feePercentage = 0
        if (this.cart.paymentMethod.fee_percentage > 0) {
          feePercentage = (processingFee + total + this.cart.paymentMethod.fee_fixed) * (this.cart.paymentMethod.fee_percentage / 100)
          paymentFee += feePercentage
        }

        // Add the VAT to the payment fee (only from the percentage)
        if(this.company.vat_percentage && paymentFee){
          paymentFeeVat = parseFloat( this.calculateVatFromEx(feePercentage, this.company.vat_percentage) )
          if(paymentFeeVat){
            paymentFee += paymentFeeVat
          }
        }

        // Rounds to two decimals
        if(paymentFee){
          paymentFee = Math.round((paymentFee + Number.EPSILON) * 100) / 100
        }

      }

      this.setCartTotalWithoutPM(parseFloat((total + processingFee).toString()))
      total += (paymentFee + processingFee)
      this.setCartTotal(total);
      return {
        total,
        productTotal,
        paymentFee,
        paymentFeeVat: paymentFeeVat,
        paymentFeeVatPerc: this.company.vat_percentage,
        processingFee,
        processingFeeVat: this.calculateVat(processingFee, this.company.vat_percentage),
        processingFeeVatPerc: this.company.vat_percentage,
        discount,
        discountVat: this.calculateVat(discount, this.company.vat_percentage),
        discountVatPerc: this.company.vat_percentage,
        totalProductCount,
        products: this.cart.products.map((p) => {
          return {
            id: p.id,
            amount: p.amount,
            total: p.amount * p.price,
            name: p.name,
            vat: p.vat * p.amount,
            vat_percentage: p.vat_percentage
          }
        })
      }
    },
    ...mapState(['cart', 'event', 'company', 'order', 'campaign', 'pos'])
  },
  created() {
    this.loadCompany();
    document.addEventListener('click', (e) => {
      if(!e.target.classList.contains('get-code-input')) {
        this.showDiscountCodeInput = false
      }
    })
  },
  beforeDestroy () {
    document.removeEventListener('click', (e) => {
      if(!e.target.classList.contains('get-code-input')) {
        this.showDiscountCodeInput = false
      }
    })
  }
}
</script>

<style scoped lang="scss">
.pos-cart-place-order {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1000;

  .get-code-input-web {
    display: block;
    padding-left: 20px !important;
  }

  span {
    font-size: 14px;
    font-weight: 600;
    word-wrap: break-word; 
    white-space: nowrap;
  }
  .rounded-small-screen{
    padding-top:15px;
    padding-bottom:15px;
  }
  .discount-input {
    border:none;
    input {
      height: 35px;
      width: 130px;
      display: inline-block;
      border-radius: 0;
      border-width:1px 0;
      box-shadow:none!important
    }
    display: inline-block;

    .input-button-percentage {
      background-color: #FFFFFF;
      border-radius: 6px 0 0 6px;
      height: 35px;
      padding:0 0 0 10px
    }

    .input-button {
      color: #FFFFFF;
      border-radius: 0 6px 6px 0;
      height: 35px;
    }
  }
  .btn.pos-btn-order {
    box-shadow: 0 3px 6px #00000029 !important;
    border-radius: 6px !important;
    font-weight:bold;
    padding:14px 12px !important;
    font-size: 16px !important;
    word-wrap: break-word; 
    white-space: nowrap;
  }
  .cart-discountcode {
    border-radius: 6px;
    padding: 5px;
    span {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .get-code-input-mobile {
    display: none;
  }
 
  .total-price{
    padding:0;
    text-align: right;
    padding-right: 10px;
  }

  @media (max-width: 992px) {
     .btn.pos-btn-order {
      font-size: 12px!important;
      white-space: normal;
      padding:9px !important
    }
    span{
      font-size:12px
    }
    .total-price{
      padding-right: 0px;
    }
    .rounded-small-screen{
      border-top-left-radius:.4rem;
      border-top-right-radius:.4rem;
      padding-top:14px;
      padding-bottom:14px;
    }
    .get-code-input-web {
      display: none !important;
    }
    .get-code-input-mobile {
      margin-left: 30px;
      display: inline-block;
      border-radius: 6px 6px 0 0;
      div {
        padding:.1rem .6rem;
      }
      .arrow-down-icon{
        border:none;
        background: transparent;
        color: black;
        padding: 0 .5rem 0 .8rem;
      }
      .discount-input{
        padding:.4rem 0;
      }
      input,button{
        height:35px!important;
        box-shadow: none;
        box-sizing: border-box;
        outline: none;
      }
    }
  }
  @media (max-width: 576px) {
    
    .cart-discountcode {
      span {
        font-size: 10px;
      }
    }
    .get-code-input-mobile {
      .arrow-down-icon{
        padding: 0 .5rem 0 .8rem;
      }
      .discount-input{
        padding:.2rem 0;
      }
      input,button{
        height:30px!important;
      }
      input{
        font-size:11px!important;
        width:110px
      }
      button{
        padding: 0 10px;
      }
    }
    .cart-shop-icon{
      width:14px!important;
      height:14px!important;
    }
   
    span {
      font-size: 10px;
    }
    span.ml-2 {
      white-space:normal !important;
    }
    .get-code-input svg{
      width: 11px !important;
      height:11px!important
    }
    .pos-cart-place-order .get-code-input-mobile div{
      padding:0.1rem 0.5rem 0
    }
    .ml-3.pos-primary-color{
      margin-left:5px!important
    }
  }
    @media (max-width: 370px) {
      .btn.pos-btn-order {
      padding:7px 6px!important
    }
  }
}
</style>
