<template>
  <div>
    <b-row>
      <b-col cols="12" sm="9">
        <h1 class="title text-left">{{ $t('layout_settings') }}</h1>
      </b-col>
      <b-col cols="12" sm="3" class=" text-left text-sm-right">
        <b-form-group>
          <b-button v-if="$can('my_company_layout_edit')" variant="primary" @click="save()">{{ $t('save') }}</b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="pb-3">
      <b-col cols="12" md="" lg="4" class="mb-4 mb-lg-0">
        <b-card :title="$t('layout_header')" >
          <b-card-text>
            <b-form-group :label="$t('layout_logo')">
              <input type="file" id="file" accept="image/*" ref="logo" v-on:change="onLogoChange()" :disabled="!$can('my_company_layout_edit')"/>
            </b-form-group>
            <b-form-group v-if="layout.logo" :label="$t('company_layout_logo_url')">
              <b-form-input v-model="layout.logo_url"></b-form-input>
            </b-form-group>
            <div class="p-3" :style="layout.header">
              <b-img fluid :src="layout.logo" v-if="layout.logo" />
              <a v-if="layout.logo && $can('my_company_layout_edit')" href="javascript:" @click="layout.logo = null" class="d-block font-14">
                {{ $t('my_company_layout_remove_image') }}
              </a>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col  cols="12" lg="4"  class="mb-4 mb-lg-0">
        <b-card :title="$t('layout_colors')">
          <b-card-text>
            <b-form-group :label="$t('layout_primary_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.primary_color"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.primary_color"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_secondary_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.secondary_color"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.secondary_color"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_accent_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.accent_color"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.accent_color"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_description_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.description_color"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.description_color"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_buttons_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.button_color"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.buttons_color"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_button_text_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.button_text_color"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.button_text_color"></div>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'
import axios from 'axios'
import {validateUrl} from "@/functions";

export default {
  data() {
    return {
      working: false,
      company: {},
      editUser: {},
      layout: {
        logo: null,
        primary_color: null,
        secondary_color: null,
        accent_color: null,
        description_color: null,
        button_color: null,
        button_text_color: null,
      }
    }
  },
  created() {
    if(this.$store.state.user) {
      if(!this.$can('my_company_layout_view') && !this.$can('admin_company_details_edit')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.loadCompany()
  },
  methods: {
    onLogoChange() {
      api.company().signLogoUpload(this.companyId, this.$refs['logo'].files[0].name).then((response) => {
        // Upload the file
        axios.create().put(response.upload_url, this.$refs['logo'].files[0], {
          headers: {
            'Content-Type': this.$refs['logo'].files[0].type
          }
        }).catch((e) => {
          // Upload failed...
          console.error(e)
        }).then(() => {
          // upload done, create the video in the database
          this.layout.logo = response.target_url
        })
      })
    },
    loadCompany() {
      if (!this.user) {
        setTimeout(this.loadCompany, 100)
        return
      }
      api.company().get(this.companyId).then(company => {
        this.company = company
        if (this.company.layout) {
          this.layout = Object.assign(this.layout, this.company.layout)
        }
      })
    },
    save() {
      if (this.layout.logo_url) {
        if (!validateUrl(this.layout.logo_url)) {
          this.$store.commit('errors', [this.$t('pos_invalid_url')]);
          return;
        }
      }
      api.company().updateLayout(this.companyId, this.layout).then(() => {
        this.loadCompany()
        this.$store.commit('success', 'layout_updated');
      })
    }
  },
  computed: {
    companyId() {
      return this.user.role === 'admin' ? this.$route.params.id : 'mine'
    },
    ...mapState(['user'])
  },
  watch:{
    '$store.state.user': {
      handler() {
        if(!this.$can('my_company_layout_view') && !this.$can('admin_company_details_edit')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.last-bar{
  margin-bottom:190px !important
}
.one-colorpicker {
  border: solid 1px silver;
}
@media (max-width:992px) {
  .title{
  font-size: 25px;
  text-align: center;
}
.card-text{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.card-text > fieldset{
  width: 50%;
  margin-bottom: 20px;
}
}
@media (max-width:576px) {
.card-text{
  display: block;
}
.card-text > fieldset{
  width: 100%;
  margin-bottom: 20px;
}
}
</style>
