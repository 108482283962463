import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import i18n from '../i18n'
import {BToast} from 'bootstrap-vue'
import moment from 'moment'

Vue.use(Vuex)
import api from '../api'
import boxOfficeApi from '../api/boxOfficeApi'
import scannerTerminalApi from '../api/scannerTerminalApi'
import {applyCampaignRule, deepClone} from "@/functions";

let startToken = localStorage.getItem('ft_token')
let isImpersonating = false
if (localStorage.getItem('ft_impersonate_token')) {
    startToken = localStorage.getItem('ft_impersonate_token')
    isImpersonating = true
}
const EMPTY_CART = {products: [], total: null, paymentMethod: null, discountCode: null, campaignDiscount: 0}
let startCart = EMPTY_CART
if (localStorage.getItem('ft_cart')) {
    startCart = JSON.parse(localStorage.getItem('ft_cart'))
}

let startLanguage = 'en'
if (localStorage.getItem('ft_lang')) {
    startLanguage = localStorage.getItem('ft_lang')
}
else {
    localStorage.setItem('ft_lang', startLanguage)
}
i18n.locale = startLanguage
moment.locale(startLanguage)

const store = new Vuex.Store({
    state: {
        token: startToken,
        user: null,
        campaign: null,
        boxOfficeUser: null,
        scannerTerminalUser: null,
        impersonating: isImpersonating,
        errors: [],
        success: null,
        company: null,
        cart: startCart,
        language: startLanguage,
        event: null,
        pos: null,
        posFirstGroupIsDefault: false,
        order: null,
        layout: null,
        donation_product_id:0,
        cartTotal: 0,
        cartTotalWithoutPM: 0,
        cartProcessingFee: 0
    },
    getters: {
        posHasCrossSell(state) {
            let hasCrossSell = false
            if (!state.pos) {
                return null
            }
            state.pos.products.forEach(posProduct => {
                if (posProduct.product.is_cross_sell) {
                    hasCrossSell = true
                }
            })
            return hasCrossSell
        }
    },
    mutations: {
        setCampaign(state, campaign) {
            state.campaign = campaign;
            this.commit('setCampaignToPos')
        },
        login(state, token) {
            state.token = token
            state.impersonating = false
            localStorage.setItem('ft_token', state.token)
        },
        loginAs(state, token) {
            state.token = token
            state.impersonating = true
            localStorage.setItem('ft_impersonate_token', token)
            api.user().me().then(user => {
                state.user = user
            })
        },
        logout(state) {
            if (state.impersonating) {
                localStorage.removeItem('ft_impersonate_token')
                state.impersonating = false
                state.token = localStorage.getItem('ft_token')
                api.user().me().then(user => {
                    state.user = user
                    router.push({
                        name: 'Companies'
                    })
                })
                return
            }

            localStorage.removeItem('ft_token')
            state.token = null
            state.user = null

            router.push({name: 'Login'})
        },
        boxOfficeLogout(state) {
            localStorage.removeItem('box_office_token')
            state.boxOfficeUser = null

            router.push({name: 'BoxOfficeLogin'})
        },
        scannerTerminalLogout(state) {
          localStorage.removeItem('scanner_terminal_token')
          state.scannerTerminalUser = null

          router.push({name: 'ScannerTerminalLogin'})
        },
        setUser(state, user) {
            state.user = user
        },
        setBoxOfficeUser(state, user) {
            state.boxOfficeUser = user
        },
        setScannerTerminalUser(state, user) {
          state.scannerTerminalUser = user
        },
        success(state, message) {
            state.success = message;
            let bootStrapToaster = new BToast()
            bootStrapToaster.$bvToast.toast(i18n.t(message), {
                toaster: state.layout === 'Shop' ? 'b-toaster-top-center' : 'b-toaster-top-right',
                solid: true,
                variant: 'success'
            })
        },
        errors(state, messages) {
            state.errors = messages.map(error => {
                if (typeof error === 'string') {
                    return error
                }

                if (error.code) {
                    return error.code
                }
            });

            state.errors.forEach(message => {
                if(message !== 'event_not_live') {
                    let bootStrapToaster = new BToast()
                    bootStrapToaster.$bvToast.toast(i18n.t(message), {
                        toaster: state.layout === 'Shop' ? 'b-toaster-top-center' : 'b-toaster-top-right',
                        solid: true,
                        variant: 'danger'
                    })
                }
            })

        },
        setEvent(state, event) {
            state.event = event
        },
        setCartProducts(state, products) {
            // Clear out any products that are invalid
            products = products.filter((product) => {
                return product.amount > 0
            }).map(product => {
                return Object.assign(product, {discount_price: applyCampaignRule(state.campaign, product.name, deepClone(product).price)})
            })

            state.cart = Object.assign(state.cart, {products})
            localStorage.setItem('ft_cart', JSON.stringify(state.cart))
        },
        setCartPaymentMethod(state, paymentMethod) {
            state.cart = Object.assign(state.cart, {paymentMethod})
            localStorage.setItem('ft_cart', JSON.stringify(state.cart))
        },
        setCartDiscountCode(state, discountCode) {
            state.cart = Object.assign(state.cart, {discountCode})
            if (state.order) {
                state.order.discountcode = discountCode ? discountCode.code : null
            }
            localStorage.setItem('ft_cart', JSON.stringify(state.cart))
        },
        setCartCampaignDiscount(state, campaignDiscount) {
            state.cart = Object.assign(state.cart, {campaignDiscount})
            localStorage.setItem('ft_cart', JSON.stringify(state.cart))
        },
        setCartTimeslot(state, timeslot){
            state.cart = Object.assign(state.cart, {timeslot})
            localStorage.setItem('ft_cart', JSON.stringify(state.cart))
        },
        clearDiscountCode(state) {
            state.cart = Object.assign(state.cart, {discountCode: null})
            state.order.discountcode = null
            localStorage.setItem('ft_cart', JSON.stringify(state.cart))
        },
        clearCart(state) {
            // state.cart = Object.assign({}, EMPTY_CART)
            state.cart = {products: [], total: null, paymentMethod: null, discountCode: null}
            localStorage.removeItem('ft_cart')
        },
        setCompany(state, company) {
            state.company = company
        },
        setPos(state, pos) {
            if (pos.event && !state.event) {
                this.commit('setEvent', pos.event)
            }
            state.pos = pos
            if(state.campaign) {
                this.commit('setCampaignToPos')
            }
            // if (pos) {
            //     if (pos.language) {
            //         this.commit('setLanguage', pos.language)
            //     }
            // }
        },
        setCampaignToPos(state) {
            if(state.pos) {
                state.pos.products = state.pos.products.map(product => {
                    product.product = Object.assign(product.product, {discount_price: applyCampaignRule(state.campaign, product.product.name, deepClone(product.product).price)})
                    return product;
                })
            }
        },
        setLanguage(state, language) {
            i18n.locale = language
            moment.locale(language)
            state.language = language
            localStorage.setItem('ft_lang', language)
            if(state.user) {
                api.user().updateLanguage(language);
            }
        },
        setOrder(state, order) {
            state.order = order

            if(order.campaign) {
                this.commit('setCampaign', order.campaign)
            }

            // Load the point of sale by it's url from the order state
            api.pos().getByUrl(state.order.point_of_sale.url).then(pos => {
                this.commit('setPos', pos)
            })

            // check if we need to clear the discount code
            if (!order.discountcode) {
                this.commit('clearDiscountCode')
            } else {
                this.commit('setCartDiscountCode', order.discountcode)
            }

            // Check if we need to set the event in the state
            if (!state.event && state.order.event) {
                this.commit('setEvent', state.order.event)
            }

            // Fill the cart if the user has lost it's state / local storage
            let cartProductIds = []
            state.order.order_products.forEach((orderProduct) => {
                cartProductIds.push(orderProduct.product.id)
            })

            let cartProducts = []
            cartProductIds.forEach(productId => {
                let product = cartProducts.find(product => {
                    return product.id === productId
                })
                if (!product) {
                    let orderProduct = state.order.order_products.find(orderProduct => {
                        return orderProduct.product_id === productId
                    })
                    product = orderProduct.product
                    product.price = orderProduct.price
                    product.amount = 0
                    cartProducts.push(product)
                }
                product = cartProducts.find(product => {
                    return product.id === productId
                })
                let productIndex = cartProducts.indexOf(product)
                cartProducts[productIndex].amount += 1
            })
            this.commit('setCartProducts', cartProducts)
        },
        setLayout(state, layout) {
            state.layout = layout
        },
        setProductTypeDonationId(state,donationId){
            state.donation_product_id = donationId
        },
        setCartTotal(state, total) {
            state.cartTotal = total;
        },
        setCartTotalWithoutPM(state, total) {
            state.cartTotalWithoutPM = total;
        },
        setProcessingFee(state, total) {
            state.cartProcessingFee = total;
        },
        setPosFirstGroupIsDefault(state, value) {
            state.posFirstGroupIsDefault = value
        }
    },
    actions: {
        loadCompany({commit, state}) {
            if (!state.company) {
                api.company().getByDomain().then(company => {
                    commit('setCompany', company)
                }).catch(() => {
                    router.push({
                        name: 'NotFound'
                    })
                })
            }
        },
        loadUser({commit, state}) {
            return new Promise((resolve, reject) => {
                if (state.user) {
                    resolve(state.user)
                    return
                }
                api.user().me().then(user => {
                    commit('setUser', user)
                    resolve(user)

                    // Set the language of specific to the user
                    if (user.language && user.language !== '') {
                        commit('setLanguage', user.language)
                    }

                    if(user.to_reset_password) {
                        router.push({name: 'UserResetPassword'});
                    }
                }).catch(reject)
            })

        },
        loadBoxOfficeUser({commit, state}) {
            return new Promise((resolve, reject) => {
                if (state.boxOfficeUser) {
                    resolve(state.boxOfficeUser)
                    return
                }
                boxOfficeApi.user().me().then(boxOfficeUser => {
                    commit('setBoxOfficeUser', boxOfficeUser)
                    resolve(boxOfficeUser)

                    // Set the language of specific to the user
                    if (boxOfficeUser.language && boxOfficeUser.language !== '') {
                        commit('setLanguage', boxOfficeUser.language)
                    }
                }).catch(reject)
            })

        },
        loadScannerTerminalUser({commit, state}) {
          return new Promise((resolve, reject) => {
              if (state.scannerTerminalUser) {
                  resolve(state.scannerTerminalUser)
                  return
              }
              scannerTerminalApi.user().me().then(scannerTerminalUser => {
                  commit('setScannerTerminalUser', scannerTerminalUser)
                  resolve(scannerTerminalUser)

                  // Set the language of specific to the user
                  if (scannerTerminalUser.language && scannerTerminalUser.language !== '') {
                      commit('setLanguage', scannerTerminalUser.language)
                  }
              }).catch(reject)
          })

      },
        loadProductTypeDonation({commit,state}){
            if (state.donation_product_id === 0 ) {
                api.product().getProductTypeDonation().then(id => {
                    commit('setProductTypeDonationId', parseInt(id))
                });
            }
        }
    }
})

export default store
